@use 'sass:math';

.product-carousel {
  .slick-slide {
    overflow: hidden;
    width: 95vw;

    @include media-breakpoint-only(sm) {
      width: 524px;
    }

    @include media-breakpoint-only(md) {
      width: 704px;
    }

    @include media-breakpoint-up(lg) {
      width: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .product-video-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;

    img {
      position: absolute !important;

      &.youtube-cover {
        object-fit: cover;
      }
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  ul.slick-dots {
    height: 25px;
    overflow: hidden;
  }
}

.product-carousel img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.product-carousel-track {
  height: 100%;

  .slick-active {
    line-height: 0;
  }

  .slick-arrow {
    height: 100%;

    svg {
      color: $grey-2;
    }
  }

  .slick-next {
    z-index: 150;
    position: absolute;
    background: rgba(0, 0, 0, 0.6);
    height: 100%;
    width: 30px;
    outline: 0;
    top: 0;
    right: 0;
    border: 0;
    border-radius: 0;
  }

  .slick-prev {
    z-index: 150;
    position: absolute;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    height: 100%;
    width: 30px;
    outline: 0;
    border: 0;
    top: 0;
    border-radius: 0;
  }

  .slick-track {
    height: auto;
    line-height: 0;

    span i {
      position: relative;
      top: -56px;
      left: 57px;
    }

    .slick-slide {
      max-width: 20%;  // Caps width to 1/5 available space for 5 slides on track
    }
  }

  .video-thumb {
    padding: 0;
    width: 100%;
  }

  .video-thumb-container {
    position: relative;
    padding-bottom: percentage(math.div(9, 16));

    img {
      position: absolute !important;
      width: 100%;
      height: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      border: 0;
    }
  }

  .play-icon-container {
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    z-index: 1;
    font-size: 20px;

    svg {
      filter: drop-shadow(0 0 6px $grey-14);
    }
  }
}

.remove-style {
  display: block;
  width: 100%;
  padding: 0;
  border: none;
}

.bundle-carousel-spacing {
  margin-top: 4px;
}

.blackout {
  background: $grey-13;
  border: 2px solid $grey-10;
  width: 100%;
  padding: 47px 0;

  @include theme(light) {
    background: map-get($light-theme, card-background);
    border-color: $grey-2;
  }
}
