.footer {
  overflow: hidden;
  font-weight: 300;
  width: 100%;
  margin-top: 1rem;

  @include theme(light) {
    font-weight: 400;
    border-top: 1px solid $grey-2;
  }

  .hidden-dot {
    color: transparent;
    height: 1px;
    width: 1px;

    @include theme(light) {
      color: map-get($light-theme, background-color);
    }
  }

  .desktop-button-container {
    animation: fadein 0.5s;
    position: sticky;
    display: none;

    @include media-breakpoint-up(sm) {
      display: block;
    }

    .back-to-top-btn {
      border-radius: unset;
      padding: 0.5rem 0 0.5rem 0;
      background-color: $card-background-dark;
      text-transform: uppercase;
      width: 100px;
      font-weight: 400;
      border: none;
      height: 40px;

      &:hover {
        background-color: $grey-11;
      }

      @include theme(light) {
        background-color: $white;
        color: $blue-grey-900;
        border-bottom: 1px solid $grey-2;

        &.btn:not(:disabled):not(.disabled) {
          color: $blue-grey-900;
          border-bottom: 1px solid $grey-2;

          &:active {
            background-color: $grey-1;
          }
        }

        &:hover {
          background-color: $grey-1;
        }
      }
    }
  }

  .footer-ways-to-pay {
    background: $grey-13;
    border-bottom: 1px solid $grey-14;
    padding: 2rem 0;
    min-height: 176px;

    @include theme(light) {
      background: $white;
      border-bottom: 1px solid $grey-2;
    }

    @include media-breakpoint-up(sm) {
      display: flex;
      align-items: center;
      min-height: unset;
    }

    @include media-breakpoint-up(md) {
      min-height: 139px;
    }

    @include media-breakpoint-up(lg) {
      min-height: 110px;
    }

    @include media-breakpoint-up(xl) {
      min-height: 121px;
    }

    @include media-breakpoint-up(xxl) {
      min-height: 130px;
    }

    .links-title-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      @include media-breakpoint-up(lg) {
        flex-direction: row;
      }

      .title-container {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1rem;
        margin-bottom: 0.75rem;

        @include media-breakpoint-up(lg) {
          margin-bottom: 0;
          margin-right: 1rem;
        }
      }

      .payment-images-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        .payment-img-column {
          margin: 2px;

          @include media-breakpoint-up(lg) {
            margin: 5px;
          }

          @include media-breakpoint-up(xl) {
            margin: 8px;
          }

          @include media-breakpoint-up(xxl) {
            margin: 10px;
          }
        }

        .payment-img {
          width: 54px;
          height: 33px;

          @include media-breakpoint-up(lg) {
            width: 57px;
            height: 35px;
          }

          @include media-breakpoint-up(xl) {
            width: 67px;
            height: 40px;
          }

          @include media-breakpoint-up(xxl) {
            width: 75px;
            height: 45px;
          }

          @include theme(light) {
            border: 1px solid $grey-2;
            border-radius: $border-radius-sm;
            overflow: hidden;
          }
        }
      }
    }
  }

  .footer-top {
    padding: 2rem 0 0.5rem;
    background: $grey-13;
    width: 100%;

    @include theme(light) {
      background: $white;
    }

    @include media-breakpoint-up(md) {
      padding: 3rem 0 1.5rem;
    }

    @include media-breakpoint-up(lg) {
      padding: 3.5rem 0 3rem;
    }

    h6 {
      text-transform: uppercase;
      font-size: 0.875rem;
      font-weight: 400;
      letter-spacing: 0.5px;

      @include theme(light) {
        font-weight: 700;
      }
    }

    .footer-links-container {
      display: flex;
      flex-wrap: wrap;

      .footer-links {
        width: 50%;
        padding: 0 0.75rem;
        margin-bottom: 2rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @include media-breakpoint-up(sm) {
          padding: unset;
        }

        @include media-breakpoint-up(md) {
          width: 33%;
        }

        @include media-breakpoint-up(lg) {
          width: 25%;
          margin-bottom: 0;
        }

        &:first-of-type {
          padding-left: 0;
        }

        &:last-of-type {
          padding-right: 0;

          @include media-breakpoint-down(md) {
            padding: 0;
          }
        }

        a,
        .footer-btn-link {
          background: none;
          padding: 0;
          border: none;
          text-decoration: none;
          color: $grey-5;
          transition: all ease 0.1s;
          font-weight: 300;
          margin-bottom: 0.5rem;

          @include theme(light) {
            color: $grey-7;
            font-weight: 400;
          }

          @include media-breakpoint-up(lg) {
            margin-bottom: 0.25rem;
          }

          &:hover {
            color: $grey-4;
            text-decoration: underline;
            cursor: pointer;

            @include theme(light) {
              color: $grey-5;
            }
          }
        }

        .footer-social {
          display: flex;
          flex-wrap: wrap;
          margin-left: -0.75rem;
          margin-right: -0.75rem;
          margin-top: 0.75rem;
        }

        .footer-social-icon {
          display: flex !important;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          padding: 0 0.75rem;
          font-size: 20px;
          margin-bottom: 0.75rem;

          svg {
            color: $white;
          }

          @include theme(light) {
            &.facebook {
              svg {
                color: $facebook-color;
              }
            }

            &.twitter {
              svg {
                color: $twitter-color;
              }
            }

            &.discord {
              svg {
                color: $discord-color;
              }
            }

            &.steam {
              svg {
                color: $steam-black;
              }
            }

            &.youtube {
              svg {
                color: $youtube-color;
              }
            }

            &.instagram {
              svg {
                color: $instagram-color;
              }
            }
          }
        }
      }

      .email-social-links {
        display: flex;
        flex-direction: column;

        @include media-breakpoint-down(md) {
          width: 100%;
        }

        @include media-breakpoint-only(md) {
          flex-direction: row;
        }

        .privacy-link {
          display: inline !important;
          margin-bottom: 0;
        }

        .email-container,
        .social-container {
          width: 100%;
          flex-shrink: 0;

          @include media-breakpoint-only(md) {
            width: 50%;
          }
        }

        .email-container {
          @include media-breakpoint-only(md) {
            padding-right: 0.75rem;
            margin-right: 0.75rem;
          }
        }
      }
    }

    .trustpilot-settings-container {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      margin-left: -0.5rem;
      margin-right: -0.5rem;
      padding-top: 1rem;
      padding-bottom: 2rem;

      @include media-breakpoint-up(lg) {
        flex-direction: row;
        padding-bottom: 0;
      }

      .trustpilot-container {
        width: 100%;
        padding: 0 0.5rem;
        display: flex;
        justify-content: center;

        @include media-breakpoint-up(lg) {
          width: 60%;
          justify-content: flex-start;
        }

        @include media-breakpoint-up(xl) {
          width: 75%;
        }

        .trustpilot-footer-link {
          font-size: 0.875rem;
          color: $text-white;
          text-decoration: none;
          display: flex;
          flex-direction: column;
          align-items: center;

          @include theme(light) {
            color: map-get($light-theme, font-color);
          }

          @include media-breakpoint-up(md) {
            flex-direction: row;
          }

          .trustpilot-footer-img {
            width: 240px;
            height: 28px;
            margin-bottom: 0.5rem;
            flex-shrink: 0;

            @include media-breakpoint-up(md) {
              margin-bottom: 0;
              margin-right: 1rem;
            }
          }

          .trustpilot-text {
            margin-top: 6px;
            font-weight: 400;
            text-align: center;

            @include media-breakpoint-up(md) {
              text-align: left;
            }
          }
        }
      }

      .settings-container {
        width: 100%;
        padding: 0 0.5rem;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: 2rem;

        @include media-breakpoint-up(lg) {
          width: 40%;
          flex-direction: row;
          justify-content: flex-end;
          margin-bottom: 0;
        }

        @include media-breakpoint-up(xl) {
          width: 25%;
        }

        .theme-toogle-container {
          display: flex;
          border: 1px solid transparent;
          text-align: center;
          align-items: center;
          border-color: $grey-7;
          flex-shrink: 0;
          border-radius: $border-radius-sm;
          justify-content: center;
          padding-left: 1rem;
          padding-right: 1rem;
          min-height: 44px;
          width: 100%;
          margin-bottom: 0.5rem;

          &:hover,
          &:focus {
            text-decoration: none;
          }

          @include media-breakpoint-up(lg) {
            width: auto;
            margin-right: 0.5rem;
            margin-bottom: 0;
          }

          @include media-breakpoint-up(xxl) {
            flex-grow: 1;
          }

          .icon-container {
            margin-right: 0.5rem;
          }

          .text-container {
            white-space: nowrap;
          }

          .switch-container {
            margin-left: 0.5rem;
          }

          @include theme(light) {
            color: map-get($light-theme, font-color);
            border-color: $grey-3;
            font-weight: 400;
          }
        }

        .btn-language-link {
          width: 100%;

          @include media-breakpoint-up(lg) {
            width: auto;
          }
        }

        .btn-language {
          width: 100%;
          border-color: $grey-7;
          min-height: 44px;
          border-radius: $border-radius-sm;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;

          svg {
            margin-left: 0.5rem;
            font-size: $font-size-xs;
          }

          &:hover,
          &:focus {
            border-color: $grey-10;
            text-decoration: none;
            box-shadow: none;
          }

          @include theme(light) {
            color: map-get($light-theme, font-color);
            border-color: $grey-3;
            font-weight: 400;
          }
        }
      }
    }

    .email-subscribe-title {
      margin-bottom: 0.75rem;
      text-transform: uppercase;
    }

    .email-subscribe {
      input {
        box-shadow: none;
        border: 1px solid map-get($light-theme, input-border-color);
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: $border-radius-sm;
        border-bottom-left-radius: $border-radius-sm;
      }
    }

    .small-text {
      font-size: 0.8125rem;
      line-height: 1.2rem;
      margin-bottom: 1.5rem;
      margin-top: 0.75rem;

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }

      @include media-breakpoint-up(lg) {
        margin-bottom: 2rem;
      }

      a {
        color: $grey-5;
      }
    }

    &.co-brand {
      padding-bottom: 0;
    }
  }

  .footer-bottom {
    padding: 2rem;
    background: $grey-14;
    text-align: center;

    @include theme(light) {
      background: $white;
      border-top: 1px solid $grey-2;
    }

    @include media-breakpoint-up(md) {
      padding: 2.5rem 0;
      text-align: left;
    }

    .footer-bottom-container {
      .fandom-information {
        margin-bottom: 1.5rem;

        .fandom-link-container {
          display: inline-block;
        }

        a {
          display: flex;
          flex-direction: column;
          align-items: center;
          color: $grey-5;

          @include media-breakpoint-up(md) {
            flex-direction: row;
            align-items: flex-end;
          }
        }

        .fandom-logo-container {
          height: 28px;
          width: 112px;
          margin-bottom: 1rem;

          @include media-breakpoint-up(md) {
            margin-bottom: 0;
          }

          img {
            height: 100%;
            width: 100%;
            object-fit: contain;
          }
        }

        .fandom-text-container {
          font-size: 15px;
          line-height: 15px;
          margin-bottom: 1px;

          @include media-breakpoint-up(md) {
            margin-left: 1.25rem;
          }

          span {
            color: $fandom-cream;
            text-decoration: underline;

            @include theme(light) {
              color: $fandom-purple;
            }
          }
        }
      }
    }

    .legalese {
      font-size: 0.875rem;
      color: $grey-5;
    }
  }

  &.minimal-footer {
    .footer-ways-to-pay {
      border: 0;
    }

    .footer-top {
      padding-top: 0;

      .footer-ways-to-pay {
        padding-top: 0;
      }
    }
  }
}
