.product-slider {
  position: relative;
  margin-left: -5px;
  margin-right: -5px;

  @include media-breakpoint-up(sm) {
    margin-left: -8px;
    margin-right: -8px;
  }

  @include media-breakpoint-up(md) {
    margin-left: -10px;
    margin-right: -10px;
  }

  @include media-breakpoint-up(xl) {
    margin-left: -15px;
    margin-right: -15px;
  }

  .HitCard,
  .video-hit-card,
  .challenger-hit-card,
  .fake-hit-card {
    margin-bottom: 0;
  }

  .HitCardContainer,
  .fake-card-container {
    padding: 0;
    margin-top: 5px;

    @include theme(light) {
      margin-bottom: map-get($light-theme, card-slider-margin);
    }
  }

  .slick-slide {
    padding: 0 5px;

    @include media-breakpoint-up(sm) {
      padding: 0 8px;
    }

    @include media-breakpoint-up(md) {
      padding: 0 10px;
    }

    @include media-breakpoint-up(xl) {
      padding: 0 15px;
    }
  }
}
