@import "../../styles/_chunk";

.lang-outer {
  background-color: $primary-core;
  padding: 0.5rem 0;
  min-height: 50px;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 510;

  @include media-breakpoint-up(lg) {
    position: absolute;
    top: 0;
    bottom: auto;
  }

  .lang-text-buttons-container {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .text-container {
      color: $text-black;
      font-weight: 400;
      font-size: 0.9375rem;
      line-height: 1.25rem;
      text-align: center;
      margin-bottom: 0.25rem;

      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
        margin-right: 1rem;
      }
    }

    .buttons-container {
      margin-left: -0.5rem;
      margin-right: -0.5rem;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      .button-container {
        padding: 0.5rem;

        @include media-breakpoint-up(lg) {
          padding: 0 0.5rem;
        }
      }
    }
  }
}

.lang-container {
  color: $black;
  text-align: center;

  .lang-button {
    margin: 0 15px;
    background: $grey-14;
    color: $grey-2;
    border: none;
    padding: 5px 10px;
  }
}
