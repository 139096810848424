@import "../../styles/_chunk";

.moe-main-class {
  position: absolute;
  z-index: 600;
  top: 0;
  left: calc(50% - 200px);
  width: 400px;
  background: $grey-16;
  padding: 20px;
  box-shadow: $grey-15 0 3px 12px;
  border-radius: 5px;
  color: $grey-2;

  .moe-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;

    p {
      width: 100%;
    }
  }

  h5 {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 20px;
    padding-top: 5px;
  }

  .moe-logo {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .moe-buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 40px;
  }

  .btn {
    padding: 0.2rem 1.25rem;
    font-size: 13px;
    font-weight: 700;
    height: 30px;
    text-transform: uppercase;
  }

  .moe-block-class {
    margin-right: 0.7rem;
    font-weight: 400;
    background: $grey-12;

    &:hover {
      background: $grey-13;
    }
  }

  p {
    font-size: 16px;
  }

  .moe-disclaimer {
    font-size: 12px;
    color: $grey-5;
    font-weight: 400;
    margin-bottom: 0;
  }
}
