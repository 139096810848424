.quick-links {
  .quicklinks-container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-top: -3px;
    margin-left: -5px;
    margin-right: -5px;
    margin-bottom: 1rem;

    @include media-breakpoint-up(md) {
      margin-bottom: 2rem;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 2.5rem;
    }

    .quick-link {
      flex-grow: 1;
      width: 50%;
      margin-bottom: 0.25rem;
      padding: 3px 5px;

      @include media-breakpoint-up(md) {
        width: 33.3%;
      }

      @include media-breakpoint-up(lg) {
        flex-grow: 0;
        width: 25%;
      }

      @include media-breakpoint-up(xl) {
        width: 20%;
      }

      a {
        height: 3.125rem;
        background-color: $card-background-dark;
        border-radius: $border-radius-sm;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        color: $text-white;
        font-size: 0.875rem;
        font-weight: 400;
        transition: all 0.2s ease-out;
        text-align: center;

        @include theme(light) {
          background-color: $grey-3;
          color: map-get($light-theme, font-color);
          font-weight: 700;
        }

        &:hover {
          background-color: $black;

          @include theme(light) {
            background-color: $grey-4;
          }
        }
      }
    }

    .extra-quick-link {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    .last-two-quick-link {
      @include media-breakpoint-only(lg) {
        display: none;
      }
    }
  }
}
