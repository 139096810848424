@import "../../styles/_chunk";

.site-side-bar {
  position: relative;

  .side-bar-close-button-container {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 0.25rem;
    margin-right: 0.25rem;

    button {
      @include theme(light) {
        color: $grey-4;
      }
    }
  }

  .side-bar {
    padding: 1rem;
    padding-top: 1.5rem;
    background-color: $grey-13;
    min-width: 280px;

    @include theme(light) {
      background-color: map-get($light-theme, background-color);
    }

    @include media-breakpoint-up(sm) {
      width: 400px;
      padding: 1.25rem;
    }

    @include media-breakpoint-up(md) {
      width: 480px;
      padding: 2rem;
    }

    @include media-breakpoint-up(lg) {
      width: 550px;
    }

    @include media-breakpoint-up(xl) {
      width: 580px;
    }

    @include media-breakpoint-up(xxl) {
      width: 630px;
    }

    .side-bar-title {
      font-size: 30px;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 0.5rem;
    }

    .side-bar-sub-title {
      text-align: center;
      margin-bottom: 1.5rem;
      font-size: 16px;
    }

    .message-box {
      margin-bottom: 1.5rem;
    }

    // CART ITEM STYLING

    .item-message {
      background-color: $blue-grey-800;
      color: $white;
      font-size: 13px;
      font-weight: 400;
      padding: 1px 5px;
    }

    .cart-item {
      background-color: $card-background-dark;
      display: flex;
      align-items: center;
      padding: 0.75rem;
      margin-bottom: 5px;
      font-size: 13px;
      border-radius: $border-radius-sm;

      @include theme(light) {
        background-color: map-get($light-theme, card-background);
        box-shadow: map-get($light-theme, card-box-shadow);
      }

      @include media-breakpoint-up(md) {
        font-size: 14px;
      }

      @include media-breakpoint-up(lg) {
        padding: 5px;
      }

      .item-cover {
        display: none;
        overflow: hidden;
        border-radius: $border-radius-sm;

        @include media-breakpoint-up(lg) {
          display: block;
          width: 90px;
          flex-shrink: 0;
          margin-right: 0.25rem;
        }

        @include media-breakpoint-up(xl) {
          width: 100px;
        }

        @include media-breakpoint-up(xxl) {
          width: 130px;
        }
      }

      .item-details-price-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include media-breakpoint-up(lg) {
          padding: 0.25rem 0.5rem;
        }

        .item-name-icons-container {
          display: flex;
          flex-direction: column;
          margin-right: 0.5rem;

          .item-icons-container {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin-top: 0.375rem;

            .os-icons-container {
              display: flex;
              font-size: 11px;

              @include theme(light) {
                color: map-get($light-theme, card-icons-color);
              }

              svg {
                margin-right: 0.5rem;

                &:last-of-type {
                  margin-right: 0;
                }
              }
            }
          }
        }

        .pricing-container {
          display: flex;
          align-items: center;

          .price-container {
            @include brand-property(color, brand-core);

            min-width: 44px;
            margin-left: 0.75rem;
            font-weight: 700;
            text-align: right;
            text-transform: uppercase;
          }

          .was-price-container {
            min-width: 44px;
            margin-left: 0.75rem;
            text-decoration: line-through;
            color: $grey-5;
            text-align: right;
            display: none;

            @include media-breakpoint-up(xl) {
              display: block;
            }
          }

          .discount-container {
            background-color: $black;
            border-radius: $border-radius-sm;
            color: $text-white;
            padding: 4px 5px;
            text-align: center;
            display: none;
            margin-left: 0.75rem;
            font-size: 13px;
            min-width: 40px;

            @include theme(light) {
              background-color: map-get($light-theme, saving-percentage-background);
              color: map-get($light-theme, font-color);
            }

            @include media-breakpoint-up(sm) {
              display: block;
            }
          }
        }
      }
    }

    .side-bar-summary {
      background-color: $card-background-dark;
      padding: 0.75rem;
      border-radius: $border-radius-sm;

      @include theme(light) {
        background-color: map-get($light-theme, card-background);
        box-shadow: map-get($light-theme, card-box-shadow);
      }

      @include media-breakpoint-up(lg) {
        padding: 1rem;
      }

      .summary-line-container {
        color: $grey-4;
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.25rem;

        @include theme(light) {
          color: $grey-7;
        }

        &.small-print {
          font-size: 0.8125rem;
        }

        &.line-breaker {
          border-top: 2px solid $grey-8;
          padding-top: 0.5rem;
          margin-top: 0.625rem;
          color: $white;

          @include theme(light) {
            color: map-get($light-theme, font-color);
            border-color: $grey-3;
          }
        }

        &.checkout-full-price {
          .summary-number {
            text-decoration: line-through;
          }
        }

        &.checkout-saving {
          .summary-number {
            color: $success-core;
            font-weight: 700;
          }
        }

        &.checkout-subtotal {
          .summary-number {
            text-decoration: line-through;
          }
        }

        &.checkout-voucher {
          .summary-number {
            color: $success-core;
            font-weight: 700;
          }
        }

        &.checkout-total {
          font-weight: 400;
          font-size: 1.125rem;

          .summary-text {
            text-transform: uppercase;

            @include theme(light) {
              color: $grey-7;
            }
          }

          .summary-number {
            @include brand-property(color, brand-core);

            font-weight: 700;
          }
        }
      }

      .side-bar-buttons-container {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        margin: 0 -0.5rem;
        margin-top: 1rem;

        @include media-breakpoint-up(md) {
          flex-direction: row;
        }

        .btn {
          width: 100%;
          text-transform: uppercase;
          font-weight: 400;

          @include media-breakpoint-up(md) {
            width: 50%;
            margin: 0 0.5rem;
          }
        }

        .btn-primary {
          font-weight: 700;
          margin-bottom: 0.5rem;

          @include media-breakpoint-up(md) {
            margin-bottom: 0;
          }
        }

        .btn-secondary {
          background-color: $black;
          border-color: $black;
        }
      }

      &.full-price {
        .summary-line-container {
          &.checkout-full-price {
            display: none;
          }

          &.checkout-total {
            border: 0;
            margin-top: 0;
            padding-top: 0;
          }
        }
      }
    }

    .recommendation-container {
      margin-top: 2rem;
      padding-bottom: 1.5rem;
      max-width: 100%;

      h3 {
        font-size: 22px;
      }

      .slick-slider {
        &.product-slider {
          margin-left: 0;
          margin-right: 0;

          @include media-breakpoint-up(sm) {
            margin-left: -8px;
            margin-right: -8px;
          }
        }

        .arrow-btn-container {
          &.btn-left {
            @include media-breakpoint-up(xl) {
              margin-left: -20px;
            }
          }

          &.btn-right {
            @include media-breakpoint-up(xl) {
              margin-right: -20px;
            }
          }
        }

        .slick-list {
          max-width: 100%;
        }

        .slick-slide {
          @include media-breakpoint-up(xl) {
            padding: 0 10px;
          }
        }
      }
    }

    .line-break {
      height: 2px;
      background-color: $grey-10;
      margin: 1.5rem 0;

      @include theme(light) {
        background-color: $grey-3;
      }
    }
  }
}
