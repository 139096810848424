.hero-container {
  display: block;
  z-index: -1;
  position: absolute;
  width: 100%;

  .bg-img-container {
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .bg-img {
    width: 150%;
    height: auto;
    margin: 0 auto;
    overflow: hidden;
    max-width: 2200px;

    @include media-breakpoint-up(sm) {
      width: 140%;
      min-width: 940px;
    }

    @include media-breakpoint-up(md) {
      min-width: 1200px;
    }

    @include media-breakpoint-up(lg) {
      min-width: 1540px;
      width: 120%;
    }

    @include media-breakpoint-up(xl) {
      min-width: 1800px;
      width: 100%;
    }

    @include media-breakpoint-up(xxl) {
      min-width: 2060px;
    }
  }
}

.mobile-takeover {
  position: relative;
  padding-bottom: 33.333%;

  @include media-breakpoint-up(md) {
    display: none;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.center-img-container {
  @include media-breakpoint-up(md) {
    margin: 10px 0 40px;
  }

  @include media-breakpoint-up(lg) {
    margin: 10px 0 50px;
  }

  @include media-breakpoint-up(xl) {
    margin: 10px 0 70px;
  }

  @include media-breakpoint-up(xxl) {
    margin: 0 0 90px;
  }

  .center-img {
    width: 100%;
  }
}

.solid-hero-container {
  height: 118px;
  position: relative;
  overflow: hidden;

  @include media-breakpoint-up(md) {
    height: 160px;
  }

  @include media-breakpoint-up(lg) {
    height: 214px;
  }

  @include media-breakpoint-up(xl) {
    height: 255px;
  }

  @include media-breakpoint-up(xxl) {
    height: 300px;
  }

  .solid-center-img-container {
    display: flex;
    align-items: center;
    height: 100%;
    position: relative;

    a {
      width: 100%;
    }

    img {
      width: 100%;
      height: 118px;
      object-fit: contain;

      @include media-breakpoint-up(md) {
        height: 160px;
      }

      @include media-breakpoint-up(lg) {
        height: 214px;
      }

      @include media-breakpoint-up(xl) {
        height: 255px;
      }

      @include media-breakpoint-up(xxl) {
        height: 300px;
      }
    }
  }

  .hero-background-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .hero-background {
      position: relative;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .hero-video-container {
    z-index: 1;
  }
}

.hero-front {
  position: relative;
  z-index: 2;
}

.strip-banner-under-takeover {
  background-color: $white;
  color: $black;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  z-index: 1;
  width: 100%;
  border: 0;
  min-height: 44px;
  font-size: 14px;
  display: block;

  @include theme(light) {
    background-color: $black;
    color: $white;
  }

  @include media-breakpoint-up(lg) {
    font-size: 15px;
  }

  &:hover {
    color: $black;

    @include theme(light) {
      color: $white;
    }
  }
}
