@import "../../../styles/_chunk";

#navbar-search {
  outline: none;
  border: none;
  padding: 0.625rem 0.75rem;
  border-top-left-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem;
}

.input-group {
  margin-bottom: 0;
}

.navbar-nav {
  .form-inline {
    display: block;
    margin-top: 0;

    @include media-breakpoint-up(md) {
      margin-top: 12px;
    }
  }
}

.autosearch .input-group,
.autosearch-dropdown {
  width: 100%;

  @include media-breakpoint-up(md) {
    max-width: 340px;
  }

  @include media-breakpoint-up(lg) {
    max-width: 380px;
  }

  @include media-breakpoint-up(xl) {
    max-width: 500px;
  }

  @include media-breakpoint-up(xxl) {
    max-width: 620px;
  }
}

.autosearch-nav {
  margin-top: 20px;
}

.autosearch {
  input {
    background: $white;
    outline: none;
    border: none;
    padding: 0.625rem 0.75rem;
    border-top-left-radius: $border-radius-sm;
    border-bottom-left-radius: $border-radius-sm;
    font-weight: 400;
    min-height: 40px;
    width: 260px;

    @include theme(light) {
      border: 1px solid $grey-3;
      border-right: 0;
    }

    @include media-breakpoint-up(md) {
      width: 320px;
    }

    @include media-breakpoint-up(lg) {
      width: 400px;
    }

    @include media-breakpoint-up(xl) {
      width: 500px;
    }

    &::placeholder {
      color: $grey-4;
    }

    &:focus {
      background: $white;
      box-shadow: none;
    }
  }

  .input-group-append {
    background: $white;
    border: none;
    font-size: 1rem;
    padding: 0.5rem 0.75rem;
    border-top-right-radius: $border-radius-sm;
    border-bottom-right-radius: $border-radius-sm;
    display: flex;
    align-items: center;
    max-height: 40px;

    @include theme(light) {
      border: 1px solid $grey-3;
      border-left: 0;
    }
  }
}
