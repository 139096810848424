@import "../../../styles/_chunk";

.trustpilotstrip {
  display: flex;
  justify-content: space-between;
  justify-items: center;
  justify-self: center;
  align-items: center;
  align-self: center;
  margin-left: 15px;
  margin-right: 15px;

  img {
    width: 200px;
    height: 23px;
    max-width: 100%;
    vertical-align: inherit;
    margin-right: 10px;
  }

  svg {
    width: 20px;
    height: 20px;
  }

  .trustpilot {
    .logo-container {
      width: 200px;

      .trust-pilot-img {
        width: 200px;
        height: 23px;
      }
    }
  }

  .message-container {
    font-size: 13px;
    line-height: 18px;

    @include media-breakpoint-up(xxl) {
      font-size: 14px;
    }

    a {
      color: $text-black;
    }
  }
}
