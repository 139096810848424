@import "../../styles/_chunk";

.affiliate-topper {
  background: $primary-core;
  font-size: 1rem;
  color: $text-black;

  .affiliate-topper-link {
    u {
      color: $text-black;
    }

    @media (max-width: 575.98px) {
      display: block;
    }
  }

  .svg-inline--fa {
    font-size: 2rem !important;
  }

  .affiliate-topper-icon {
    position: absolute;
    right: 0;
    margin-right: 15px;
    height: 2rem;

    @media (max-width: 575.98px) {
      top: 5px;
    }
  }
}
