.signup-modal {
  max-width: 450px !important;
  position: relative;
  margin: 1rem auto;

  @include media-breakpoint-down(xs) {
    max-width: 94% !important;
  }

  @include media-breakpoint-up(md) {
    margin: 2rem auto;
    max-width: 680px !important;
    height: 100vh;
    display: flex;
    align-items: center;
  }

  @include media-breakpoint-up(lg) {
    margin: 3rem auto;
    max-width: 800px !important;
  }

  @include media-breakpoint-up(xl) {
    max-width: 840px !important;
  }

  .modal-content {
    border-radius: 0;
    background-color: $white;
    color: $blue-grey-900;
    box-shadow: 0 0 20px $grey-14;
    border: 0;

    .modal-body {
      padding: 0 !important;
    }
  }

  p {
    font-weight: 400;
  }

  @media #{$retina-screen} {
    p {
      font-weight: inherit;
    }
  }

  .sign-in-button {
    width: 100%;
    border-radius: 0;
    text-transform: uppercase;
    font-weight: 700;

    @include media-breakpoint-up(md) {
      margin-top: 0.5rem;
    }
  }

  .sign-in-text-icon-container {
    display: flex;
    width: 100%;
    align-items: center;

    .btn-text {
      flex-grow: 1;
    }
  }

  .link-btn-primary {
    color: $primary-core;
    text-decoration: underline;
    padding: 0;
  }

  .auth-btn-secondary {
    width: 100%;
    border-radius: 0;
    color: $blue-grey-900;
    border: 1px solid $blue-grey-900;
    font-weight: 400;

    &:hover {
      background-color: $blue-grey-50;
    }
  }

  .switch-pane-button {
    width: 100%;
    border: 1px solid $white;
    height: 38px;
    background-color: transparent;
    color: $white;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    transition: all 0.15s ease-in-out;

    &:hover {
      background-color: rgba($white, 0.8);
      border: 1px solid rgba($white, 0.8);
      color: $blue-grey-900;
    }
  }

  .close-button-container {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 24px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    margin: 5px;

    button {
      width: 100%;
      background: transparent;
      border: 0;
      padding: 0;
    }

    svg {
      color: $blue-grey-900;

      @include media-breakpoint-up(md) {
        color: $white;
      }
    }
  }

  .content-container {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-direction: row;

      &.reverse-content {
        flex-direction: row-reverse;
      }
    }

    .auth-title {
      font-weight: 700;
      text-transform: uppercase;
      font-size: 1.5rem;

      @include media-breakpoint-up(sm) {
        font-size: 1.75rem;
      }

      @include media-breakpoint-up(lg) {
        font-size: 2rem;
      }
    }

    .auth-message {
      font-weight: 400;
      text-align: center;
    }

    .auth-subtitle {
      font-weight: 700;
      text-transform: uppercase;
      font-size: 18px;
      margin-bottom: 1rem;
    }

    .auth-description-container {
      margin-bottom: 1.5rem;
    }

    .form-container {
      width: 100%;
      padding: 24px;
      display: flex;
      align-items: center;

      @include media-breakpoint-up(sm) {
        padding: 30px;
      }

      @include media-breakpoint-up(md) {
        width: 60%;
      }

      @include media-breakpoint-up(xl) {
        padding: 40px;
      }

      .form-content {
        width: 100%;

        .welcome-links-container {
          display: flex;
          flex-wrap: wrap;
          margin: 0 -0.5rem;
          margin-bottom: 0.5rem;

          .welcome-link {
            width: 50%;
            padding: 0.5rem;

            a {
              color: $primary-core;
              font-weight: 400;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }

      .full-width-subtext {
        margin-left: -9rem;
        margin-right: -9rem;
        text-align: center;
        font-weight: 300;
      }

      .success-message {
        font-weight: 700;
        color: $success-core;
      }

      .error-message {
        color: $error-core;
        margin-bottom: 1rem;
        font-weight: 700;
      }

      .auth-title {
        margin-bottom: 1rem;
      }

      input[type=radio] {
        width: 18px;
        height: 18px;
        margin-right: 10px;
      }

      input[type=text],
      input[type=checkbox],
      input[type=email],
      input[type=number],
      input[type=password] {
        box-shadow: 0 0 10px rgba($black, 0.1);
        border: 1px solid map-get($light-theme, input-border-color);
        border-radius: 0;
        padding: 10px;
        transition: all 0.15s ease-in-out;
        -webkit-appearance: none;

        &[type=text],
        &[type=email],
        &[type=number],
        &[type=password] {
          height: 40px;
          width: 100%;
        }

        &::placeholder {
          opacity: 0.4;
        }

        &.input-error {
          border-color: $error-core;
        }
      }

      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      textarea:-webkit-autofill,
      textarea:-webkit-autofill:hover,
      textarea:-webkit-autofill:focus,
      select:-webkit-autofill,
      select:-webkit-autofill:hover,
      select:-webkit-autofill:focus {
        box-shadow: 0 0 10px rgba($black, 0.1);
        transition: background-color 5000s ease-in-out 0s;
      }

      label.form-field {
        width: 100%;
        margin-bottom: 1rem;

        p {
          margin-bottom: 10px;
        }
      }

      .password-field-container {
        position: relative;

        button.show-button {
          position: absolute;
          right: 0;
          top: 0;
          height: 100%;
          width: 40px;
          color: $grey-4;
          background: transparent;
          border: 0;
          padding: 0;
        }
      }

      .remember-forgot-container {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;

        .remember-container {
          .form-field {
            display: flex;
            align-items: center;
            margin-bottom: 0;

            input[type="checkbox"] {
              display: inline-block;
              float: left;
              width: 18px;
              height: 18px;
              position: relative;
              appearance: none;
              -webkit-appearance: none;
              border: 1px solid map-get($light-theme, input-border-color);
              text-align: center;
              margin: 0 0.25rem 0 0;
              line-height: 1.5rem;
              cursor: pointer;
              outline-color: map-get($light-theme, input-border-color);
              margin-right: 10px;
              background-color: $white;
              padding: 2px;
              box-shadow: 0 0 10px rgba($black, 0.1);

              &:checked {
                border: 1px solid map-get($light-theme, input-border-color);
                background-color: $white;
              }

              &:checked::before {
                content: '';
                width: 100%;
                height: 100%;
                display: block;
                // position: absolute;
                background: url("data:image/svg+xml;utf8,<svg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 342.357 342.357' style='enable-background:new 0 0 342.357 342.357; color:red' xml:space='preserve'><polygon points='290.04,33.286 118.861,204.427 52.32,137.907 0,190.226 118.862,309.071 342.357,85.606 '/></svg>") no-repeat center center/100%;
              }

              svg {
                color: red;
              }
            }
          }
        }

        .forgot-password-container {
          a {
            color: $primary-core;
            text-decoration: underline;
          }
        }
      }

      .sign-in-with {
        margin: 1.75rem 0;

        p {
          width: 100%;
          text-align: center;
          border-bottom: 2px solid $grey-3;
          line-height: 0.1em;
          margin: 10px 0 20px;

          span {
            background: $white;
            padding: 0 10px;
          }
        }
      }

      .sign-in-buttons-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @include media-breakpoint-only(xs) {
          justify-content: center;
        }

        .sign-in-button-container {
          width: 100%;
          position: relative;
          flex-grow: 1;
          margin-bottom: 0.5rem;

          @include media-breakpoint-only(xs) {
            max-width: 400px;
          }

          &:last-of-type {
            margin-bottom: 0;
          }

          @include media-breakpoint-up(lg) {
            width: 47%;
            margin-bottom: 0;
          }

          .facebook-button-small {
            border: 1px solid $blue-grey-900;
            background-color: transparent;
            width: 100%;
            padding: 0.375rem 0.75rem;
            padding-left: 2rem;
            color: $blue-grey-900;
            transition: all 0.15s ease-in-out;
            border-radius: 4px; //inline with google login
            border-color: #dadce0;
            min-height: 40px;

            &:hover {
              background-color: #f8fafe; //inline with google login
              border-color: #d2e3fc; //inline with google login
            }
          }

          .auth-btn-secondary {
            padding-left: 2rem;
            border-radius: 4px; // inline with google login
            border-color: #dadce0;
            min-height: 40px;
            height: 100%;

            &:hover {
              background-color: #f8fafe; //inline with google login
              border-color: #d2e3fc; //inline with google login
            }
          }

          .sign-in-icon-container {
            position: absolute;
            top: 0;
            left: 0;
            font-size: 20px;
            height: 100%;
            display: flex;
            align-items: center;
            margin-left: 0.5rem;

            &.facebook-icon {
              color: $facebook-color;
            }

            &.fandom-icon img {
              width: 18px;
              height: auto;
            }

            &.magic-icon {
              color: $purple-800;
              font-size: 18px;
            }
          }

          &.fandom-register {
            .auth-btn-secondary {
              @include media-breakpoint-up(lg) {
                padding-left: 0.75rem;
                display: flex;
                align-items: center;
                justify-content: center;
              }

              .fandom-icon {
                @include media-breakpoint-up(lg) {
                  position: relative;
                  margin-right: 0.4375rem;
                  margin-left: 0;
                }
              }
            }
          }
        }

        &.multiple-buttons {
          margin-bottom: 0.5rem;

          @include media-breakpoint-up(lg) {
            margin-bottom: 1rem;
          }
        }

        @include media-breakpoint-up(lg) {
          &.multiple-buttons {
            .sign-in-button-container:nth-child(even) {
              margin-left: 0.5rem;
            }

            .sign-in-button-container:nth-child(odd) {
              margin-right: 0.5rem;
            }
          }
        }
      }

      .email-continue-container {
        margin-top: 1rem;
      }

      .terms-container {
        font-size: 12px;
        margin-top: 1rem;

        p {
          margin: 0;
        }

        a {
          color: $primary-core;
          text-decoration: underline;
        }
      }

      .two-factor-form {
        .btn-primary {
          margin-top: 1.5rem;
        }
      }
    }

    .switch-pane-container {
      width: 100%;
      padding: 20px;
      background-color: $blue-grey-900;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      display: none;
      color: $text-white;
      text-align: center;
      position: relative;

      @include media-breakpoint-up(sm) {
        padding: 30px;
      }

      @include media-breakpoint-up(md) {
        display: flex;
        align-items: center;
        width: 40%;
        min-height: 450px;
      }

      @include media-breakpoint-up(xl) {
        padding: 40px;
      }

      &.newsletter-upsell {
        text-align: left;

        .auth-subtitle {
          margin-bottom: 0.5rem;
        }

        .switch-pane-text {
          margin-bottom: 1rem;
        }

        .blue-background {
          opacity: 0.92;
        }
      }

      .switch-pane-content {
        z-index: 2;
        width: 100%;
      }

      .blue-background {
        position: absolute;
        background-color: $blue-grey-900;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0.7;
      }

      .auth-title {
        margin-bottom: 1rem;
      }

      .switch-pane-text {
        font-size: 15px;
        margin-bottom: 1.5rem;

        @include media-breakpoint-up(lg) {
          font-size: 16px;
        }
      }
    }

    .mobile-switch-tab-container {
      padding: 20px;
      width: 100%;
      background-color: $blue-grey-900;

      @include media-breakpoint-up(md) {
        display: none;
      }

      .mobile-email-benefits {
        color: $text-white;

        .auth-subtitle {
          margin-bottom: 0.5rem;
        }

        .switch-pane-text {
          font-size: 13px;
        }
      }
    }
  }

  .sign-up-forgot-password-container {
    margin-top: -20px;
    margin-bottom: 20px;

    .link-btn-primary {
      color: $error-core;
      font-weight: 600;
    }
  }

  &.reset-password {
    .login-button {
      width: 100%;
      border-radius: 0;
      text-transform: uppercase;
      font-weight: 700;
    }

    .close-button-container {
      svg {
        color: $blue-grey-900;

        @include media-breakpoint-up(md) {
          color: $blue-grey-900;
        }
      }
    }
  }

  &.prelogin-pane,
  &.confirm-email-pane {
    .content-container {
      .form-container {
        width: 100%;

        .prelogin-switch-container {
          display: flex;
          flex-direction: column;
          margin-top: 1rem;

          @include media-breakpoint-up(sm) {
            flex-direction: row;
          }

          @include media-breakpoint-up(md) {
            margin-top: 1.5rem;
          }

          @include media-breakpoint-up(lg) {
            margin-top: 2rem;
          }

          .login-container {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-bottom: 2rem;

            @include media-breakpoint-up(sm) {
              width: 50%;
              border-right: 2px solid map-get($light-theme, input-border-color);
              padding: 1rem;
              padding-left: 0;
              margin-bottom: 0;
            }

            @include media-breakpoint-up(md) {
              padding: 1.5rem;
            }

            @include media-breakpoint-up(lg) {
              padding: 2rem;
            }
          }

          .register-container {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            @include media-breakpoint-up(sm) {
              width: 50%;
              border-left: 1px solid map-get($light-theme, input-border-color);
              padding: 1rem;
              padding-right: 0;
            }

            @include media-breakpoint-up(md) {
              padding: 1.5rem;
            }

            @include media-breakpoint-up(lg) {
              padding: 2rem;
            }
          }

          .user-choice-text {
            font-weight: 400;
            margin-bottom: 1rem;
          }
        }

        .btn-primary {
          width: 100%;
          text-transform: uppercase;
          font-weight: 700;
          padding: 0.75rem;
          border-radius: 0;

          &.sign-in {
            background-color: $orange-200;
            border-color: $orange-200;

            &:hover {
              background-color: $primary-core;
              border-color: $primary-core;
            }
          }
        }

        .email-confirm-container {
          display: flex;
          margin-bottom: 1rem;
          justify-content: center;

          .btn-primary {
            width: 100%;
            @include media-breakpoint-up(md) {
              width: 42%;
            }
          }
        }
      }
    }

    .close-button-container {
      svg {
        color: $blue-grey-900;
      }
    }
  }

  &.checkout.login-pane,
  &.checkout.register-pane {
    .content-container {
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .switch-pane-container {
        display: none;
      }

      .fraud-banner-container {
        padding: 20px;
        padding-bottom: 0;
        width: 100%;

        @include media-breakpoint-up(sm) {
          padding: 30px;
          padding-bottom: 0;
        }

        @include media-breakpoint-up(xl) {
          padding: 40px;
          padding-bottom: 0;
        }

        .auth-title {
          text-transform: none;
          font-size: 28px;

          svg {
            font-size: 28px;
            margin-bottom: 2px;
            margin-right: 0.75rem;
          }
        }

        .fraud-banner {
          background-color: $grey-2;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-bottom: -1rem;
          margin-top: 1.5rem;

          @include media-breakpoint-up(md) {
            flex-direction: row;
          }

          .banner-point {
            width: 100%;
            padding: 0.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            line-height: 14px;
            font-weight: 400;

            @include media-breakpoint-up(md) {
              padding: 1rem;
              width: 33.333%;
            }

            svg {
              margin-right: 0.5rem;
              font-size: 18px;
            }

            &.point-1 {
              @include media-breakpoint-up(md) {
                padding-right: 0.5rem;
              }
            }

            &.point-2 {
              @include media-breakpoint-up(md) {
                padding-left: 0.5rem;
                padding-right: 0.5rem;
              }
            }

            &.point-3 {
              @include media-breakpoint-up(md) {
                padding-left: 0.5rem;
              }
            }
          }
        }
      }
    }

    .close-button-container {
      svg {
        color: $blue-grey-900;
      }
    }
  }
}
