.card-price-container {
  display: flex;
  align-items: center;

  .price-wasprice-container {
    line-height: 14px;
    font-size: 14px;
    text-align: right;

    .was-price {
      margin-right: 0 !important;
      margin-bottom: 2px;
      color: $grey-5;
      text-decoration: line-through;
    }

    .card-price {
      @include brand-property(color, brand-core);

      font-weight: 700;

      .from-container {
        margin-bottom: 2px;
      }
    }
  }

  .card-saving {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 14px;
    line-height: 14px;
    background-color: $black;
    color: $white;
    font-weight: 400;
    padding: 0.25rem 0.25rem;
    min-height: 32px;
    min-width: 46px;
    margin-right: 0.5rem;
    border-radius: $border-radius-sm;
    text-transform: uppercase;

    @include theme(light) {
      background-color: map-get($light-theme, saving-percentage-background);
      color: map-get($light-theme, font-color);
    }

    @include media-breakpoint-down(sm) {
      position: absolute;
      left: 0;
      margin-left: 0.5rem;
    }

    .tiered-text {
      text-transform: uppercase;
      font-size: 9px;
      line-height: 9px;
    }
  }

  &.top-seller {
    .card-saving {
      font-size: 16px;
      line-height: 16px;
      min-height: 38px;
      min-width: 52px;
    }

    .price-wasprice-container {
      .was-price {
        font-size: 16px;
        line-height: 16px;
        margin-bottom: 3px;
      }

      .card-price {
        font-size: 16px;
        line-height: 16px;
      }
    }
  }

  &.tiered {
    .price-wasprice-container {
      .card-price {
        display: flex;
        flex-direction: column;
      }
    }
  }

  &.show-saving {
    .card-saving {
      min-width: 60px;
      font-size: 15px;
      line-height: 16px;
    }
  }
}
