@import "../../styles/_chunk";

.SlimBanner {
  width: 100%;
  height: 40px;

  .SlimBanner__container {
    height: 100%;
  }

  .SlimBanner__content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center;

    .SlimBanner__logoButton {
      display: flex;
      align-items: center;
      justify-content: center;

      .SlimBanner__saleLogo {
        display: flex;
        height: 40px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .SlimBanner__button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $white;
        color: $black;
        font-family: "Lato-Bold", "Lato Bold", "Lato", sans-serif;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
        margin-left: 1rem;
        font-size: 10px;
        width: 100px;
        height: 22px;
        padding: 2px 2px 2px 2px;
        border-radius: 2px;

        @include media-breakpoint-down(xs) {
          display: none;
        }

        &:hover {
          opacity: 0.85;
        }
      }
    }

    .SlimBanner__partnerLogo {
      position: absolute;
      right: 0;
      display: flex;
      width: auto;
      height: 24px;

      @include media-breakpoint-down(sm) {
        display: none;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
