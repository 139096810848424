.homepage {
  position: relative;

  .carousel-section-margin {
    margin-bottom: 3rem;
  }

  .flash-deals-section-carousel {
    margin-top: 1.75rem;
    margin-bottom: 3rem !important;

    @include media-breakpoint-up(lg) {
      margin-top: 3rem;
      margin-bottom: 3.5rem !important;
    }

    .section-heading-container-between {
      h2,
      h4 {
        margin: 0;
      }
    }
  }

  .current-deals-container {
    padding-top: 0.75rem;

    .section-heading-container {
      justify-content: space-between;
      flex-direction: column;

      @include media-breakpoint-up(sm) {
        flex-direction: row;
      }

      h4 {
        margin-bottom: calc(0.5rem - 5px);
      }

      .nextdeal-countdown-container {
        display: flex;
        font-size: 18px;

        .countdown {
          margin-left: 0.5rem;

          .digit-container {
            font-weight: 700;
          }

          .colon {
            margin: 0 2px;
          }
        }
      }
    }
  }

  .trending-carousel-title {
    margin-top: 2rem !important;
  }

  .homepage-ads-top-container {
    @include media-breakpoint-up(xs) {
      min-height: 653px;
    }

    @include media-breakpoint-up(sm) {
      min-height: 900px;
    }

    @include media-breakpoint-up(md) {
      min-height: 714px;
    }

    @include media-breakpoint-up(lg) {
      height: 392px;
      min-height: 0;
    }

    @include media-breakpoint-up(xl) {
      height: 458px;
    }

    @include media-breakpoint-up(xxl) {
      height: 540px;
    }
  }

  .SaleButton {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;

    @include media-breakpoint-up(md) {
      margin-bottom: 2.5rem;
    }

    @include media-breakpoint-up(xl) {
      margin-bottom: 3rem;
    }
  }

  .secondary-ad-container {
    display: flex;
    justify-content: center;
    height: 100%;

    a {
      flex-grow: 1;
    }
  }

  @include media-breakpoint-only(md) {
    .home-coming-soon,
    .home-new-releases,
    .home-latest-deals.latest-deals-count-4 {
      .HitCardContainer:nth-child(4) {
        display: none;
      }
    }
  }

  .home-latest-deals {
    .notification-banner {
      margin-bottom: 1rem;

      .banner-content {
        padding: 0 0.5rem;
      }
    }
  }

  .email-subscribe-container {
    .email-subscribe-box-large {
      margin-bottom: 1rem;

      @include media-breakpoint-only(md) {
        margin-bottom: 2rem;
      }

      @include media-breakpoint-up(lg) {
        margin-bottom: 3rem;
      }
    }
  }

  .product-carousel-carousel-container {
    padding-bottom: 3rem;
  }

  .trending-deals-two-row-carousel {
    padding-bottom: 3rem;

    .desktop-carousel {
      display: none;

      @include media-breakpoint-up(lg) {
        display: block;
      }
    }

    .medium-carousel {
      display: none;

      @include media-breakpoint-up(md) {
        display: block;
      }

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    .mobile-carousel {
      display: block;

      @include media-breakpoint-up(md) {
        display: none;
      }
    }

    .two-row-carousel {
      .top-row {
        margin: 0 -15px 1.25rem;

        @include media-breakpoint-up(md) {
          margin: 0 -15px 1.75rem;
        }

        @include media-breakpoint-up(xl) {
          margin: 0 -15px 2.5rem;
        }
      }

      .bottom-row {
        margin-right: -15px;
        margin-left: -15px;
      }

      .arrow-btn-container {
        top: 35px;

        @include media-breakpoint-up(lg) {
          top: 25px;
        }
      }

      .HitCardContainer {
        padding: 0 15px;
      }
    }
  }

  .fan-grid-container {
    @include media-breakpoint-only(md) {
      .four-column-row:nth-child(4) {
        display: none;
      }
    }
  }

  .more-great-deals-container {
    // margin-bottom: 0.5rem;

    .slick-dots {
      margin-top: calc(0.5rem + 6px);
    }
  }

  .flash-deals-carousel {
    margin-bottom: 2rem !important;
    padding-top: 0.75rem;

    @include media-breakpoint-up(lg) {
      margin-bottom: 3rem !important;
    }
  }

  .omen-optimizer-banner {
    margin-bottom: -1rem;
  }

  .omen-free-games-banner {
    margin-bottom: -1rem;
  }

  .fake-footer-banner {
    min-height: 380px;

    @include media-breakpoint-up(md) {
      padding: 0;
      min-height: 180px;
    }

    @include media-breakpoint-up(lg) {
      height: 200px;
    }

    @include media-breakpoint-up(xl) {
      height: 220px;
    }

    @include media-breakpoint-up(xxl) {
      height: 260px;
    }
  }

  .top-sellers-bundles-section {
    .big-hit-card .big-card-sdesc {
      font-size: 0.9375rem;

      @include theme(dark) {
        color: $text-white;
      }
    }

    .large-hit-cards-container {
      display: none;

      @include media-breakpoint-up(xl) {
        display: flex;
      }
    }

    .medium-hit-cards-container {
      display: flex;
      @include media-breakpoint-up(xl) {
        display: none;
      }

      @include media-breakpoint-only(xs) {
        .HitCardContainer {
          &:last-of-type {
            display: none;
          }
        }
      }
    }
  }
}

// BLOG SECTION

.homepage-blog-section {
  .blog-post-card {
    @include theme(light) {
      box-shadow: map-get($light-theme, card-box-shadow);
    }
  }
}

// EBOOK SECTION

.homepage-ebook-section {
  background-color: map-get($dark-theme, secondary-background-color);
  padding-top: 2rem;
  padding-bottom: 4rem;
  margin-bottom: 3rem;

  @include theme(light) {
    background-color: map-get($light-theme, secondary-background-color);
  }

  .view-all-card-container {
    @include theme(light) {
      border: 1px solid $grey-3;
      padding-bottom: calc(56.25% + 3rem - 2px);
    }
  }
}

// FAN FAVOURITES

.fan-favourites {
  margin-bottom: 5rem;
}

// WISHLIST SECTION

.homepage-wishlist-section {
  background-color: map-get($dark-theme, secondary-background-color);
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-bottom: 3rem;
  margin-top: 2rem;

  @include theme(light) {
    background-color: map-get($light-theme, secondary-background-color);
  }

  .section-heading-container {
    justify-content: space-between;
  }

  .product-slider {
    margin-bottom: 3rem;
  }

  .wishlist-upsell {
    text-align: center;
    font-size: 15px;

    a {
      color: map-get($dark-theme, font-color);

      @include theme(light) {
        color: map-get($light-theme, font-color);
      }

      text-decoration: underline;

      &:hover {
        @include brand-property(color, brand-core);
      }
    }
  }

  .wishlist-sale-secondary-text {
    display: none;
    color: $text-muted;
    font-size: 15px;

    @include media-breakpoint-up(md) {
      display: flex;
      align-items: center;
    }
  }

  .HitCard:hover,
  .hit-mega-card:hover {
    .hit-card-overlay,
    .top-product-overlay {
      background-color: rgba($black, 0.92);
    }
  }
}
