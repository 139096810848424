.product-commerce-container {
  flex: 0 0 auto;

  .responsive-image {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;

    img {
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }
  }

  .product-title-review-container {
    margin: 0.75rem 0;
  }

  .price-container {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    height: 40px;

    .price {
      @include brand-property(color, brand-core);

      font-size: 1.5rem;
      font-weight: 700;
      text-transform: uppercase;

      b {
        font-weight: 700;
      }
    }

    .was-price {
      font-size: 1.5rem;
      color: $grey-5;
      position: relative;
      text-decoration: line-through;
      margin-left: 0.75rem;
    }

    .saving-percentage {
      color: $text-white;
      height: 40px;
      font-size: 1.125rem;
      padding: 8px;
      background-color: $black;
      margin-left: 1rem;
      display: flex;
      align-items: center;
      border-radius: $border-radius-sm;

      @include theme(light) {
        background-color: map-get($light-theme, saving-percentage-background);
        color: map-get($light-theme, font-color);
      }
    }
  }

  .featured-deal-timer {
    color: $text-muted;
    font-weight: 400;
    font-size: 14px;
    margin-top: 0.5rem;

    span {
      color: $stardeal-core;
    }
  }

  .card-title {
    text-transform: uppercase;
    font-size: 1.5rem;
    margin-bottom: 0.625rem;

    b {
      font-weight: 700;
    }
  }

  .sd-timer svg {
    margin-top: -4px;
  }

  .bundle-reminder {
    display: flex;
    align-items: center;
    margin-top: 0.625rem;

    svg {
      margin-right: 0.5rem;
    }
  }

  .edit-price-button {
    text-decoration: underline;
    padding: 0.1rem 0 0 0;
    margin-bottom: -10px;
  }

  .cart-btn {
    p,
    b {
      flex-grow: 0;
    }
  }

  .btn-promotion {
    @include theme(light) {
      @include button-variant($grey-3, $grey-3);

      font-weight: 400;
    }
  }

  .free-to-play-support-text {
    text-align: center;
    font-weight: 400;
    margin: 0.75rem 0 0;
  }

  .approx-native-price {
    text-transform: none;
    font-size: 0.875rem;
  }

  .drm-container {
    margin-right: 10px;
  }

  .platforms-container {
    font-size: 13px;
    display: flex;

    @include theme(light) {
      color: map-get($light-theme, card-icons-color);
    }

    span {
      margin-right: 5px;
    }
  }

  .product-soldout {
    background-color: $grey-10;
    cursor: not-allowed;
    color: $grey-4;

    @include theme(light) {
      background-color: $grey-3;
      color: map-get($light-theme, font-color);
    }

    a {
      color: $grey-4;

      @include theme(light) {
        color: map-get($light-theme, font-color);
      }
    }
  }
}

.product-giveaway-newsletter-required {
  .subscribe-area {
    background-color: $black;
    padding: 0.5rem 0.75rem;
    margin-bottom: 0.5rem;

    @include theme(light) {
      background-color: $grey-3;
    }

    .subscribe-text {
      font-weight: 700;
      margin-bottom: 0.5rem;
    }

    .checkbox-label-container {
      display: flex;
      align-items: center;
      margin-bottom: 0.25rem;

      label {
        margin: 0;
      }

      input[type="checkbox"] {
        display: inline-block;
        float: left;
        width: 18px;
        height: 18px;
        position: relative;
        appearance: none;
        -webkit-appearance: none;
        background-color: $white;
        text-align: center;
        margin: 0 0.5rem 0 0;
        line-height: 1.5rem;
        cursor: pointer;
        flex-shrink: 0;
        border: 1px solid map-get($light-theme, input-border-color);

        @include theme(light) {
          border: 1px solid $grey-4;
        }

        &:checked {
          border: 2px solid $info-core;
          background-color: $info-core;
        }

        &:checked::before {
          content: '';
          width: 100%;
          height: 100%;
          display: block;
          position: absolute;
          background: url("data:image/svg+xml;utf8,<svg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 342.357 342.357' style='enable-background:new 0 0 342.357 342.357' xml:space='preserve'><polygon fill='white' points='290.04,33.286 118.861,204.427 52.32,137.907 0,190.226 118.862,309.071 342.357,85.606 '/></svg>") no-repeat center center/100%;
        }

        &:focus {
          outline: 0;
        }
      }
    }
  }

  .subscribe-smallprint {
    color: $grey-4;
    font-size: 13px;

    @include theme(light) {
      color: $grey-5;
    }

    a {
      color: $white;

      @include theme(light) {
        color: $text-black;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.sold-out {
    border: 2px solid $error-core;
    padding: 0.5rem 0.75rem;

    .subscribe-area {
      background-color: transparent;
      padding: 0;

      .subscribe-text {
        color: $error-core;
        display: flex;
        align-items: center;

        svg {
          margin-right: 0.5rem;
        }
      }

      .sold-out-text {
        font-size: 14px;
        margin-bottom: 0.5rem;
      }

      .email-subscribe {
        button {
          font-weight: 700;
        }
      }
    }
  }
}
