button,
.btn {
  &:focus {
    box-shadow: none !important;
    outline: none !important;
  }
}

.btn {
  font-size: $font-size-sm;
  border-radius: $border-radius-sm;

  @include media-breakpoint-up(xxl) {
    font-size: 0.9375rem;
  }
}

.btn-sm {
  border-radius: $border-radius-sm;
}

// Primary Button

.btn-primary {
  @include brand-property(color, font-color, important);
  @include brand-property(background-color, brand-core, important);
  @include brand-property(border-color, brand-core, important);

  @include brand(hpomen) {
    border-radius: 2px !important;
    font-family: 'Montserrat', 'Verdana', sans-serif;
    font-weight: 700;
    background-image: linear-gradient(to right, $hpomen-pink, $hpomen-red, $hpomen-orange);
    border: 0;
    color: $white !important;

    b {
      font-weight: inherit;
    }
  }

  font-weight: 400;

  &:hover,
  &:focus,
  &:active {
    @include brand-property(background-color, brand-dark, important);
    @include brand-property(border-color, brand-dark, important);

    @include brand(hpomen) {
      background-image: linear-gradient(to right, $hpomen-core, $hpomen-core, $hpomen-core);
    }

    box-shadow: none;
  }
}

// Primary Outline Button

.btn-outline-primary {
  @include brand-property(color, brand-core, important);
  @include brand-property(border-color, brand-core, important);

  @include brand(hpomen) {
    border-radius: 2px !important;
    font-family: 'Montserrat', 'Verdana', sans-serif;
  }

  font-weight: 400;

  &:hover {
    @include brand-property(color, font-color, important);
    @include brand-property(background-color, brand-core, important);
  }
}

.btn-simple-outline {
  @include brand-property(color, brand-core);

  width: 100% !important;
  border: 1px solid $primary-core;
  background-color: transparent;
  font-size: 1rem;
  transition: all 0.15s ease-in-out;
  font-weight: 400;
  color: $primary-core;

  &:hover {
    color: $primary-core;
  }

  @include media-breakpoint-up(md) {
    width: 80% !important;
  }

  @include media-breakpoint-up(lg) {
    width: 60% !important;
  }

  @include media-breakpoint-up(xl) {
    width: 50% !important;
  }

  @include media-breakpoint-up(xxl) {
    width: 40% !important;
  }
}

.btn-fanatical-secondary {
  @include button-variant($blue-grey-600, $blue-grey-600);
}

.btn-secondary {
  @include button-variant($btn-secondary-bg, $btn-secondary-bg);

  @include theme(light) {
    @include button-variant($blue-grey-900, $blue-grey-900);
  }
}

.btn-error {
  @include button-variant($error-core, $error-core);

  font-weight: 400;
}

.btn-grey {
  @include button-variant($grey-4, $grey-4);

  font-weight: 400;
}

.btn-white {
  @include button-variant($white, $white, $white);

  font-weight: 400;
}

.btn-gold {
  @include button-variant(#dba344, #dba344);

  font-weight: 400;

  &:active {
    color: $grey-14 !important;
  }
}

.btn-outline-secondary {
  @include button-outline-variant(
    $btn-outline-secondary-border-color,
    $btn-outline-secondary-color
  );

  &:hover {
    color: $white;
  }
}

.btn-outline-grey {
  color: $white;
  border: 1px solid $grey-5;

  @include theme(light) {
    color: $grey-7;
    border-color: $grey-5;
  }

  &:hover {
    border-color: $white;
    color: $white;

    @include theme(light) {
      border-color: $grey-13;
      color: $grey-13;
    }
  }
}

.btn-link-primary {
  @include brand-property(color, brand-core);

  padding: 0;
  border: 0;

  @include theme(light) {
    font-weight: 400;
  }

  &:hover {
    @include brand-property(color, brand-core);

    text-decoration: underline;
  }
}

.btn-small-print {
  font-size: 0.8125rem !important;
}

.buttonIcon {
  text-transform: uppercase;
  padding: 0.3125rem;
  display: flex;
  align-items: center;
  border-radius: $border-radius-sm;
  height: 50px;

  .buttonIcon__iconContainer {
    background-color: $grey-14;
    color: $text-white;
    padding: 0.5rem 0.625rem;
    font-size: 15px;
    width: 40px;
    flex-shrink: 0;
    border-radius: $border-radius-sm;
    margin-right: 0.3125rem;
  }

  .buttonIcon__text {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 1rem;
    margin: 0 0.5rem;
  }
}

.cart-btn {
  text-transform: uppercase;
  padding: 5px;
  display: flex;
  align-items: center;
  border-radius: $border-radius-sm;
  height: 51px;

  .cart-btn-icon {
    float: left;
    background-color: $grey-14;
    color: $text-white;
    padding: 0.5rem 0.6rem;
    align-items: flex-start;
    border-radius: $border-radius-sm;
    font-size: 15px;
    width: 40px;
    flex-shrink: 0;
  }

  .btn-text-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  b,
  strong,
  p {
    flex-grow: 1;
    align-self: center;
    margin: 0;
    font-size: 1rem;
    padding: 0 0.5rem;

    @include media-breakpoint-up(lg) {
      font-size: 1.125rem;
    }
  }

  &.presale-wishlist-btn {
    b,
    p, {
      flex-grow: 1 !important;
    }

    &.btn-secondary {
      background-color: $grey-10;
      border-color: $grey-10;

      &:hover {
        background-color: $grey-8;
        border-color: $grey-8;
      }

      @include theme(light) {
        background-color: $grey-2;
        border-color: $grey-2;
        color: map-get($light-theme, font-color);

        &:hover {
          background-color: $grey-3;
          border-color: $grey-3;
        }
      }

      b,
      strong,
      p {
        font-size: 1rem;

        @include media-breakpoint-up(lg) {
          font-size: 1.0625rem;
        }
      }
    }
  }

  &.dropdown-toggle {
    b,
    p, {
      padding-right: 0.75rem;
    }
  }
}

.btn-reset-style {
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  background: transparent !important;

  &:focus {
    outline: none !important;
  }
}

// Theme Switch

.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 21px;
  margin-bottom: 0;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.theme-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $grey-10;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.theme-slider::before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 4px;
  bottom: 4px;
  background-color: $white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .theme-slider {
  background-color: $info-core;
}

input:focus + .theme-slider {
  box-shadow: 0 0 1px $info-core;
}

input:checked + .theme-slider::before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.theme-slider.round {
  border-radius: 34px;
}

.theme-slider.round::before {
  border-radius: 50%;
}
