#tooltip {
  position: relative;
  color: $white;
  font-weight: 300;
  font-size: 0.875rem;
  text-align: center;
}

#tooltip-left-icon {
  width: max-content;
}

.mobile-sizing {
  font-size: 13px;
}

.arrow_box {
  position: absolute;
  background: $grey-16;
  padding: 5px;
  border-radius: 2px;
  z-index: 9999;
  top: 10px;
}

.arrow_box::after,
.arrow_box::before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow_box::after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: $grey-16;
  border-width: 7px;
  margin-left: -10px;
}

.arrow_box::before {
  border-color: rgba(242, 242, 242, 0);
  border-bottom-color: $grey-16;
  border-width: 7px;
  margin-left: -11px;
}

.tooltip-content {
  display: flex;
  flex-direction: row;

  div {
    align-self: center;
  }
}
