.drm-container {
  display: flex;

  img {
    max-width: 100%;
  }
}

.drm-container-steam {
  width: 63px;
  height: 20px;

  &.small {
    width: 55px;
    height: 18px;
  }

  &.large {
    @include media-breakpoint-up(xl) {
      width: 80px;
      height: 25px;
    }
  }
}

.drm-container-uplay {
  width: 74px;
  height: 24px;

  &.small {
    width: 62px;
    height: 20px;
  }

  &.large {
    @include media-breakpoint-up(xl) {
      width: 80px;
      height: 26px;
    }
  }
}

.drm-container-rockstar {
  width: 83px;
  height: 26px;

  &.small {
    width: 68px;
    height: 22px;
  }

  &.large {
    @include media-breakpoint-up(xl) {
      width: 86px;
      height: 26px;
    }
  }
}

.drm-container-esonline {
  width: 100px;
  height: 18px;

  &.small {
    width: 90px;
    height: 16px;
  }

  &.large {
    @include media-breakpoint-up(xl) {
      width: 120px;
      height: 21px;
    }
  }
}

.drm-container-bethesda {
  width: 80px;
  height: 13px;

  &.small {
    width: 70px;
    height: 11px;
  }

  &.large {
    @include media-breakpoint-up(xl) {
      width: 100px;
      height: 15px;
    }
  }
}

.drm-container-oculus {
  width: 78px;
  height: 14px;

  &.small {
    width: 62px;
    height: 11px;
  }

  &.large {
    @include media-breakpoint-up(xl) {
      width: 90px;
      height: 16px;
    }
  }
}

.drm-container-switch {
  width: 72px;
  height: 16px;

  &.small {
    width: 62px;
    height: 13px;
  }

  &.large {
    @include media-breakpoint-up(xl) {
      width: 90px;
      height: 20px;
    }
  }
}

.drm-container-threeds {
  width: 82px;
  height: 10px;

  &.small {
    width: 70px;
    height: 10px;
  }

  &.large {
    @include media-breakpoint-up(xl) {
      width: 110px;
      height: 14px;
    }
  }
}

.drm-container-epicgames {
  width: 22px;
  height: 26px;

  &.small {
    width: 18px;
    height: 22px;
  }

  &.large {
    @include media-breakpoint-up(xl) {
      width: 30px;
      height: 35px;
    }
  }
}

.drm-container-origin {
  width: 60px;
  height: 22px;

  &.small {
    width: 53px;
    height: 19px;
  }

  &.large {
    @include media-breakpoint-up(xl) {
      width: 70px;
      height: 26px;
    }
  }
}

.drm-container-gog {
  width: 24px;
  height: 22px;

  &.small {
    width: 20px;
    height: 18px;
  }

  &.large {
    @include media-breakpoint-up(xl) {
      width: 30px;
      height: 27px;
    }
  }
}

.drm-container-magix {
  width: 68px;
  height: 12px;

  &.small {
    width: 58px;
    height: 10px;
  }

  &.large {
    @include media-breakpoint-up(xl) {
      width: 90px;
      height: 15px;
    }
  }
}

.drm-container-zenva {
  width: 63px;
  height: 15px;

  &.small {
    width: 54px;
    height: 13px;
  }

  &.large {
    @include media-breakpoint-up(xl) {
      width: 80px;
      height: 18px;
    }
  }
}

.drm-container-utalk {
  width: 55px;
  height: 20px;

  &.small {
    width: 44px;
    height: 15px;
  }

  &.large {
    @include media-breakpoint-up(xl) {
      width: 70px;
      height: 23px;
    }
  }
}

.drm-container-playstation {
  width: 96px;
  height: 17px;

  &.small {
    width: 70px;
    height: 13px;
  }

  &.large {
    @include media-breakpoint-up(xl) {
      width: 120px;
      height: 22px;
    }
  }
}

.drm-container-roblox {
  width: 70px;
  height: 13px;

  &.small {
    width: 62px;
    height: 11px;
  }

  &.large {
    @include media-breakpoint-up(xl) {
      width: 100px;
      height: 17px;
    }
  }
}

.drm-container-xbox {
  width: 67px;
  height: 20px;

  &.small {
    width: 54px;
    height: 16px;
  }

  &.large {
    @include media-breakpoint-up(xl) {
      width: 90px;
      height: 27px;
    }
  }
}

.drm-container-eaplay {
  width: 62px;
  height: 20px;

  &.small {
    width: 54px;
    height: 18px;
  }

  &.large {
    @include media-breakpoint-up(xl) {
      width: 84px;
      height: 27px;
    }
  }
}
