@import "../../../styles/_chunk";

.StarDealTab {
  background-color: map-get($light-theme, card-background);
  box-shadow: map-get($light-theme, card-box-shadow);
  display: flex;
  align-items: center;
  margin-bottom: 2.5rem;
  border-radius: $border-radius;
  overflow: hidden;
  color: $text-black;

  @include media-breakpoint-up(xxl) {
    min-height: 245px;
  }

  &:hover {
    color: $text-black;
  }

  .StarDealTab__coverContainer {
    width: 40%;
    padding: 0.75rem;
    padding-right: 0;

    .product-cover-container {
      border-radius: $border-radius-sm;
    }
  }

  .StarDealTab__detailsContainer {
    width: 60%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .StarDealTab__detailsTop {
      .StarDealTab__detailsTop__name {
        font-size: 1.25rem;
        margin-bottom: 0.25rem;

        @include media-breakpoint-up(xxl) {
          font-size: 1.375rem;
          margin-bottom: 0.375rem;
        }
      }

      .StarDealTab__detailsTop__reviews {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;

        .StarDealTab__detailsTop__stars {
          display: flex;
          margin-right: 1rem;
          color: $text-muted;

          .star-rating-container {
            margin-right: 0.25rem;

            .star {
              svg {
                width: 16px;

                &.star-half-icon {
                  width: 8px;
                }
              }
            }
          }
        }

        .StarDealTab__detailsTop__critics {
          display: flex;
          align-items: center;

          .StarDealTab__detailsTop__criticsScore {
            background-color: $metacritic-green;
            color: $white;
            font-weight: 700;
            width: 26px;
            height: 26px;
            font-size: 14px;
            margin-right: 0.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .StarDealTab__detailsTop__criticsLogo {
            display: flex;

            img {
              height: 100%;
              width: 100%;
              object-fit: contain;
            }

            &--metacritic {
              width: 85px;
              height: 20px;
            }

            &--opencritic {
              width: 87px;
              height: 18px;
            }
          }
        }
      }

      .progress {
        background-color: $grey-4;
        color: $white;
        margin-bottom: 0.75rem;
        margin-top: 0.5rem;

        @include media-breakpoint-up(xxl) {
          margin-bottom: 1rem;
          margin-top: 0.75rem;
        }
      }

      .StarDealTab__detailsTop__timer {
        color: $text-muted;

        span {
          color: $stardeal-core;
        }
      }
    }

    .StarDealTab__detailsBottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 0.5rem;

      .StarDealTab__detailsBottom__iconsContainer {
        display: flex;
        align-items: center;
        justify-content: center;

        .drm-container {
          margin-right: 1rem;
        }

        svg {
          color: map-get($light-theme, card-icons-color);
        }
      }

      .StarDealTab__detailsBottom__priceContainer {
        .card-price-container {
          .card-saving {
            background-color: map-get($light-theme, saving-percentage-background);
            color: map-get($light-theme, font-color);
          }
        }
      }
    }
  }
}
