.ais-Pagination,
.pagination {
  width: 100%;
  list-style: none;
  padding: 0;
  margin-bottom: 1.5rem;
  display: flex;
}

.pagination .break {
  display: none;
}

.ais-Pagination-item--firstPaget,
.ais-Pagination-item--lastPage {
  visibility: hidden !important;
  width: 0;
  height: 0;
}

.ais-Pagination-item--disabled,
span.ais-Pagination-linkDisabled {
  visibility: hidden !important;
  width: 0;
  height: 0;
}

.ais-Pagination-link,
.page-item:first-child .page-link,
.page-item:last-child .page-link,
.page-item .page-link {
  float: left;
  background: none;
  outline: none;
  border: 1px solid $grey-10;
  color: $grey-8;
  margin: 0 4px;
  padding: 0;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.25rem;
  text-align: center;
  border-radius: 50%;
  font-weight: 400;
  cursor: pointer;

  @include theme(light) {
    border-color: $grey-3;
  }

  &:hover {
    text-decoration: none;
    color: $text-white;
    border-color: $grey-8;

    @include theme(light) {
      color: $grey-8;
      border-color: $grey-4;
    }
  }
}

.ais-Pagination-link--selected,
.page-item.active .page-link {
  background: $grey-2 !important;
  color: $text-black !important;
  border: 1px solid $grey-10 !important;
  text-decoration: none;
  cursor: default;

  @include theme(light) {
    background: $grey-2 !important;
    border-color: $grey-4 !important;
  }

  &:hover {
    background: $grey-2;
    color: $text-black;

    @include theme(light) {
      background: $grey-2;
      color: $text-black;
    }
  }
}

.search-pagination {
  .ais-Pagination {
    display: flex;
    justify-content: center;
  }
}

.algolia-paginate {
  margin-top: 1rem;

  @include media-breakpoint-up(md) {
    margin-top: 0;
  }
}
