@import "../../../styles/_chunk";

@media #{$retina-screen} {
  .MegaMenu {
    font-weight: 300;
  }
}

.MegaMenuContainer {
  position: relative;
}

.MegaMenu {
  position: absolute;
  top: 0;
  left: 0;
  color: $text-black;
  display: none;
  font-weight: 400;
  text-transform: none;
  min-height: 320px;
  border-radius: $border-radius;
  background-color: $white;
  width: 942px;
  margin-left: 8px;
  overflow: hidden;

  @include theme(light) {
    border: 1px solid $grey-3;
  }

  @include media-breakpoint-up(lg) {
    min-height: 370px;
  }

  @include media-breakpoint-up(xl) {
    width: 1124px;
    min-height: 410px;
  }

  @include media-breakpoint-up(xxl) {
    width: 1324px;
    min-height: 465px;
  }

  &--visible {
    display: flex;
  }
}

// ProductsMegaMenu

.ProductsMegaMenu {
  display: flex;
  width: 100%;

  .ProductsMegaMenu__tabLinks {
    background-color: $grey-1;
    width: 20%;
    padding: 1.5rem;
  }

  .ProductsMegaMenu__tab {
    width: 80%;
    padding: 1.5rem;
    display: none;

    @include media-breakpoint-up(xxl) {
      padding: 2rem;
      padding-top: 1.5rem;
    }

    &--visible {
      display: block;
    }

    .ProductsMegaMenu__tabTitle {
      font-size: 1.375rem;
      font-weight: 400;
      margin-bottom: 0.5rem;

      &--stardeal {
        color: $stardeal-core;
        display: flex;
        align-items: center;
        font-weight: 400;
      }

      .ProductsMegaMenu__tabTitle__starDealImg {
        width: 28px;
        height: 28px;
        margin-right: 4px;
        margin-top: -12px;

        img {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }
    }

    .ProductsMegaMenu__tabText {
      font-size: 0.875rem;
      color: $text-muted;
      margin-bottom: 1.25rem;
    }

    .ProductsMegaMenu__tabGrid {
      display: flex;
      margin-bottom: 1.25rem;

      .HitCardContainer {
        .HitCard {
          margin: 0;
        }
      }
    }

    .ProductsMegaMenu__tabProductLinks {
      margin-bottom: 1.25rem;

      ul,
      ol,
      li {
        display: inline;
      }

      ul,
      ol {
        padding: 0;
        margin: 0;
        list-style: none;
      }

      li {
        font-weight: 400;
      }

      a {
        color: $text-black;

        &:hover {
          @include brand-property(color, brand-core);

          text-decoration: underline;
        }
      }
    }

    .ProductsMegaMenu__tabButton {
      @include brand-property(background-color, brand-core);
      @include brand-property(border-color, brand-core, important);
      @include brand-property(color, font-color);

      @include brand(hpomen) {
        border-radius: 2px !important;
        font-family: 'Montserrat', 'Verdana', sans-serif;
        font-weight: 700;
        background-image: linear-gradient(to right, $hpomen-pink, $hpomen-red, $hpomen-orange);
        border: 0;
        color: $white !important;
      }

      border: 1px solid $primary-core;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: $border-radius-sm;
      width: 100%;
      text-transform: uppercase;
      padding: 0.375rem 0.75rem;
      text-align: center;
      min-height: 38px;
      transition: all 0.15s ease-in-out;
      font-weight: 700;

      &:hover {
        @include brand-property(background-color, brand-dark);

        @include brand(hpomen) {
          background-image: linear-gradient(to right, $hpomen-core, $hpomen-core, $hpomen-core);
        }
      }
    }
  }

  .ProductsMegaMenu__linksContainer {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .ProductsMegaMenu__link {
    display: flex;
    align-items: center;
    font-size: 13px;
    margin-bottom: 5px;

    @include media-breakpoint-up(xl) {
      font-size: 14px;
      margin-bottom: 8px;
    }

    @include media-breakpoint-up(xxl) {
      font-size: 15px;
      margin-bottom: 10px;
    }

    .ProductsMegaMenu__link__tag {
      color: $text-black;
      font-weight: 700;

      &:hover {
        @include brand-property(color, brand-core);
      }

      &--active {
        @include brand-property(color, brand-core);
      }
    }
  }
}

// CategoriesMegaMenu

.CategoriesMegaMenu {
  display: flex;
  width: 100%;

  .CategoriesMegaMenu__linksContainer {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .CategoriesMegaMenu__link {
    font-weight: 700;
    font-size: 13px;
    margin-bottom: 3px;

    @include media-breakpoint-up(xl) {
      font-size: 14px;
      margin-bottom: 4px;
    }

    @include media-breakpoint-up(xxl) {
      font-size: 15px;
      margin-bottom: 5px;
    }

    &--title {
      @include brand-property(color, brand-core);

      text-transform: uppercase;

      a {
        @include brand-property(color, brand-core, important);

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &--sublink {
      font-weight: 400;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    a {
      color: $text-black;

      &:hover {
        @include brand-property(color, brand-core);
      }
    }
  }

  .CategoriesMegaMenu__featuredSection {
    background-color: $grey-1;
    width: 20%;
    padding: 1.5rem;

    .CategoriesMegaMenu__link {
      margin-bottom: 5px;

      @include media-breakpoint-up(xl) {
        margin-bottom: 8px;
      }

      @include media-breakpoint-up(xxl) {
        margin-bottom: 10px;
      }
    }
  }

  .CategoriesMegaMenu__mainSection {
    width: 80%;
    padding: 1.5rem;

    @include media-breakpoint-up(xxl) {
      padding: 2rem;
      padding-top: 1.5rem;
    }

    .CategoriesMegaMenu__grid {
      display: flex;
      margin: 0 -0.5rem;

      @include media-breakpoint-up(xl) {
        margin: 0 -0.75rem;
      }

      @include media-breakpoint-up(xxl) {
        margin: 0 -1rem;
      }

      .CategoriesMegaMenu__column {
        width: 25%;
        padding: 0 0.5rem;

        @include media-breakpoint-up(xl) {
          padding: 0 0.75rem;
        }

        @include media-breakpoint-up(xxl) {
          padding: 0 1rem;
        }
      }
    }
  }
}
