.message-box {
  background-color: $white;
  color: $text-black;
  text-align: left;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  border: 2px solid $grey-5;
  border-left: 0;

  .message-box-icon-container {
    padding: 5px;
    width: 40px;
    flex-shrink: 0;

    .message-box-icon {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .message-box-button {
    padding: 5px;
  }

  .message-box-message {
    padding: 12px 10px;
    flex-grow: 1;
    font-size: 14px;
    line-height: 16px;

    .message-box-title {
      font-weight: 700;
      text-transform: capitalize;
    }

    .item-adding {
      text-transform: capitalize;
    }

    p {
      margin: 0;
    }

    a {
      color: $text-black;
      text-decoration: underline;
    }

    .btn-link {
      font-size: inherit;
      color: $text-black;
      text-decoration: underline;
      font-weight: 400;
    }
  }

  .message-box-button-container {
    button {
      padding: 0.5rem 0.75rem;
      cursor: pointer;
    }
  }

  &.message-box-info {
    border-color: $warning-core;

    .message-box-icon-container {
      color: $text-black;
      background-color: $warning-core;
    }
  }

  &.message-box-error {
    border-color: $error-core;

    .message-box-icon-container {
      color: $white;
      background-color: $error-core;
    }
  }

  &.message-box-success {
    border-color: $success-core;

    .message-box-icon-container {
      color: $white;
      background-color: $success-core;
    }
  }
}
