@import "../../../styles/_chunk";

.star-rating-buttons-container {
  display: inline-flex;

  &:hover {
    .star-rating-button {
      color: $three-star-yellow;

      @include theme(light) {
        color: $three-star-yellow;
      }

      svg {
        fill: $three-star-yellow;

        @include theme(light) {
          fill: $three-star-yellow;
        }
      }
    }
  }

  .star-rating-button {
    background: none;
    border: none;
    padding: 0;
    color: $zero-star-grey-dark;
    z-index: 10;
    display: flex;

    @include theme(light) {
      color: $zero-star-grey-light;
    }

    svg {
      font-size: 18px;
      margin-right: 3px;
      color: $zero-star-grey-dark;
      fill: $zero-star-grey-dark;

      @include theme(light) {
        color: $zero-star-grey-light;
        fill: $zero-star-grey-light;
      }

      @include media-breakpoint-up(xxl) {
        font-size: 20px;
        width: 24px;
        height: 100%;
      }
    }

    &:last-of-type {
      svg {
        margin-right: 0;
      }
    }
  }

  // STAR 1
  .star-1:hover ~ .star-2,
  .star-1:hover ~ .star-3,
  .star-1:hover ~ .star-4,
  .star-1:hover ~ .star-5 {
    color: $zero-star-grey-dark;

    @include theme(light) {
      color: $zero-star-grey-light;
    }

    svg {
      fill: $zero-star-grey-dark;

      @include theme(light) {
        fill: $zero-star-grey-light;
      }
    }
  }

  // STAR 2
  .star-2:hover ~ .star-3,
  .star-2:hover ~ .star-4,
  .star-2:hover ~ .star-5 {
    color: $zero-star-grey-dark;

    @include theme(light) {
      color: $zero-star-grey-light;
    }

    svg {
      fill: $zero-star-grey-dark;

      @include theme(light) {
        fill: $zero-star-grey-light;
      }
    }
  }

  // STAR 3
  .star-3:hover ~ .star-4,
  .star-3:hover ~ .star-5 {
    color: $zero-star-grey-dark;

    @include theme(light) {
      color: $zero-star-grey-light;
    }

    svg {
      fill: $zero-star-grey-dark;

      @include theme(light) {
        fill: $zero-star-grey-light;
      }
    }
  }

  // STAR 4
  .star-4:hover ~ .star-5 {
    color: $zero-star-grey-dark;

    @include theme(light) {
      color: $zero-star-grey-light;
    }

    svg {
      fill: $zero-star-grey-dark;

      @include theme(light) {
        fill: $zero-star-grey-light;
      }
    }
  }

  .highlighted-star {
    color: $three-star-yellow;

    @include theme(light) {
      color: $three-star-yellow;
    }

    svg {
      fill: $three-star-yellow;

      @include theme(light) {
        fill: $three-star-yellow;
      }
    }
  }

  // LIGHT THEME

  &.stars-light-theme {
    &:hover {
      .star-rating-button {
        color: $three-star-yellow;

        svg {
          fill: $three-star-yellow;
        }
      }
    }

    .star-rating-button {
      color: $zero-star-grey-light;

      svg {
        fill: $zero-star-grey-light;
      }
    }

    // STAR 1
    .star-1:hover ~ .star-2,
    .star-1:hover ~ .star-3,
    .star-1:hover ~ .star-4,
    .star-1:hover ~ .star-5 {
      color: $zero-star-grey-light;

      svg {
        fill: $zero-star-grey-light;
      }
    }

    // STAR 2
    .star-2:hover ~ .star-3,
    .star-2:hover ~ .star-4,
    .star-2:hover ~ .star-5 {
      color: $zero-star-grey-light;

      svg {
        fill: $zero-star-grey-light;
      }
    }

    // STAR 3
    .star-3:hover ~ .star-4,
    .star-3:hover ~ .star-5 {
      color: $zero-star-grey-light;

      svg {
        fill: $zero-star-grey-light;
      }
    }

    // STAR 4
    .star-4:hover ~ .star-5 {
      color: $zero-star-grey-light;

      svg {
        fill: $zero-star-grey-light;
      }
    }

    .highlighted-star {
      color: $three-star-yellow;

      svg {
        fill: $three-star-yellow;
      }
    }
  }
}

// GENERAL STAR RATINGS - NOT BUTTONS

.star-rating-container {
  display: flex;

  .star {
    display: flex;
    position: relative;

    svg {
      width: 22px;
      height: 100%;
      fill: $zero-star-grey-dark;
      margin-right: 3px;

      @include theme(light) {
        fill: $zero-star-grey-light;
      }
    }

    svg.star-half-icon {
      width: 11px;
      position: absolute;
      fill: $three-star-yellow !important;
    }

    &.highlighted-star {
      svg {
        fill: $three-star-yellow;
      }
    }

    &:last-of-type {
      svg {
        margin-right: 0;
      }
    }
  }

  &.stars-light-theme {
    .star {
      svg {
        fill: $zero-star-grey-light;
      }

      &.highlighted-star {
        svg {
          fill: $three-star-yellow;
        }
      }
    }
  }
}
