.site-dark-theme {
  background-color: map-get($dark-theme, background-color) !important;
  color: map-get($dark-theme, font-color) !important;
  font-weight: map-get($dark-theme, font-weight-regular) !important;
  transition: all ease 0.3s;
}

.site-light-theme {
  background-color: map-get($light-theme, background-color) !important;
  color: map-get($light-theme, font-color) !important;
  font-weight: map-get($light-theme, font-weight-regular) !important;
  transition: all ease 0.3s;
  min-height: 100vh;
}

body {
  font-size: $font-size-sm;

  @include media-breakpoint-up(xxl) {
    font-size: 0.9375rem;
  }
}

img {
  max-width: 100%;
}

ol.reset-list,
ul.reset-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.disable-link {
  pointer-events: none;
}

// -------- POSITION

.position-absolute {
  position: absolute;
}

.position-relative {
  position: relative;
}

.position-fixed {
  position: fixed;
}

.position-static {
  position: static;
}

.position-sticky {
  position: sticky;
}

.homepage-section-margin {
  margin-bottom: 3rem;
}

// Remove margin from the bottom row of cards that is on .HitCard

.grid-row-negative-margin {
  margin-bottom: -1.25rem;

  @include media-breakpoint-up(md) {
    margin-bottom: -1.75rem;
  }

  @include media-breakpoint-up(xl) {
    margin-bottom: -2.5rem;
  }
}

// -------- CARDS

ul.HitCardsRow {
  padding: 0;
  list-style-type: none;
  margin-bottom: 0;

  &--marginReset {
    margin-bottom: -1.25rem;

    @include media-breakpoint-up(md) {
      margin-bottom: -1.75rem;
    }

    @include media-breakpoint-up(xl) {
      margin-bottom: -2.5rem;
    }
  }
}

.card {
  background-color: $card-background-dark;
  color: $text-white;

  @include theme(light) {
    background-color: map-get($light-theme, card-background);
    box-shadow: map-get($light-theme, card-box-shadow);
    color: map-get($light-theme, font-color);
  }
}

.card-bg {
  background-color: $card-background-dark;

  @include theme(light) {
    background-color: map-get($light-theme, card-background);
  }
}

// -------- SIDEBAR

.side-bar {
  height: 100%;
  width: 25%;
  min-width: 280px;
  position: fixed;
  top: 0;
  right: 0;
  padding: 0;
  list-style: none;
  background-color: $card-background-dark;
  overflow-y: scroll;
  z-index: 1000;
  transition: 300ms ease-in-out;
  transform: translateX(100%);

  @include theme(light) {
    background-color: map-get($light-theme, background-color);
  }

  &.side-bar-reveal {
    transition: 300ms ease-in-out !important;
    transform: translateX(0%) !important;
  }
}

.site-overlay {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  background-color: $black !important;
  opacity: 0.75;
  z-index: 600;
  width: 100%;
  border: 0;
}

// -------- BADGES

.dlc-badge {
  background-color: $dlc;
  color: $text-white;
  font-weight: 700;
  font-size: 10px;
  border-radius: $border-radius-sm;
  padding: 0.125rem 0.25rem;

  @include media-breakpoint-up(xl) {
    font-size: 11px;
    line-height: 11px;
    padding: 0.25rem 0.375rem;
  }

  &--large {
    font-size: 11px;
    line-height: 11px;
    padding: 0.25rem 0.375rem;

    @include media-breakpoint-up(xl) {
      font-size: 14px;
      line-height: 14px;
      padding: 0.375rem 0.75rem;
    }
  }
}

.tick-icon {
  color: $success-core;
}

.tick-icon-circle {
  color: $white;
}

.fa-stack {
  height: auto;
  width: auto;
}

// -------- NAV

.nav-tabs {
  .nav-link {
    border-top-left-radius: $border-radius-sm;
    border-top-right-radius: $border-radius-sm;
  }
}

// -------- FORMS

input {
  font-size: 1rem;

  @include media-breakpoint-up(sm) {
    font-size: 0.9375rem;
  }
}

input[type="radio"],
select {
  &:focus {
    outline: 0 !important;
    box-shadow: none !important;
  }
}

.form-control {
  font-size: 1rem;
  font-weight: 400;
  border-color: $grey-4;
  background-color: $white;

  @include media-breakpoint-up(sm) {
    font-size: 0.9375rem;
  }

  &:focus {
    background-color: $white;
  }
}

.input-group {
  background-color: transparent !important;

  .input-group-append {
    button {
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
  }
}

.dropdown-menu {
  border-radius: $border-radius-sm;

  &.dropdown-right {
    right: 0;
    left: auto;
  }
}

// -------- IMAGES

.img-full {
  max-width: 100%;
}

.img-force-full {
  width: 100%;
}

.background-img-fluid {
  height: inherit;
  width: 100%;
}

.product-cover-container {
  overflow: hidden;

  .responsive-image {
    overflow: hidden;
  }
}

.portrait-image {
  object-fit: contain;
  height: 100%;
  z-index: 2;
}

.portrait-image-background {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute !important;
  filter: blur(20px) brightness(0.75);
  transform: scale(1.1);
  top: 0;
  left: 0;
  z-index: 1;
  object-fit: cover;
}

// -------- TABLES

table.table-dark {
  thead {
    th {
      padding: 1em 1.5em;
      color: $table-dark-color;
      border: 0;
    }

    tr {
      border: 0;
    }
  }

  tr {
    border-bottom: 0.3em solid $grey-11;
    background-color: $grey-10;
  }

  td {
    border: 0;
  }
}

.order-table {
  background-color: $grey-10;

  th,
  td {
    padding: 0.5rem 1rem;
    border-top: 0;
  }
}

// -------- TYPOGRAPHY

h1,
h2,
h3,
h4,
h5,
h6 {
  @include theme(light) {
    font-weight: map-get($light-theme, font-weight-regular);
  }
}

.introduction-text {
  line-height: 2;

  a {
    color: $text-white;
    text-decoration: underline;

    @include theme(light) {
      color: $text-black;
    }

    &:hover {
      @include brand-property(color, brand-core);

      text-decoration: underline;
    }
  }
}

.font-small {
  font-size: $font-size-sm;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.text-muted {
  @include theme(light) {
    color: $grey-7 !important;
  }
}

.text-error {
  color: $error-core;
  font-weight: 700;
}

.text-success {
  color: $success-core;
  font-weight: 700;
}

.text-primary {
  @include brand-property(color, brand-core, important);
}

.brand-line {
  background-color: $primary-core;
  height: 0.3rem;
  width: 2.5rem;
}

a.brand-link {
  @include brand-property(color, brand-core);

  &:hover {
    text-decoration: underline;
  }
}

// -------- 404 PAGE

.not-found-title {
  font-size: 2rem;

  @include media-breakpoint-up(md) {
    font-size: 3rem;
  }
}

// -------- REFER SHARE BUTTONS

.refer-share-buttons {
  margin-left: -0.375rem;
  margin-right: -0.375rem;
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.5rem;

  .refer-share-buttton {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
  }

  .react-sharing-button__link {
    margin: 0;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $border-radius;

    &.whatsapp {
      background-color: $whatsapp-color;

      &:hover {
        background-color: darken($whatsapp-color, 7%);
      }
    }
  }
}

// -------- SEO BLURB

.seo-blurb-text {
  color: $grey-5;

  @include theme(light) {
    color: $grey-7;
  }
}

// -------- PRODUCT SECTIONS

.section-heading-container-extra {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
  padding-bottom: 1rem;

  @include media-breakpoint-up(md) {
    margin-top: 1rem;
  }

  .section-heading-container {
    margin-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .section-heading-extra {
    font-size: 15px;

    @include media-breakpoint-up(lg) {
      font-size: 16px;
    }

    @include media-breakpoint-up(xl) {
      font-size: 18px;
    }
  }
}

.section-heading-container {
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
  margin-top: 1rem;

  h2 {
    margin-bottom: 0;
  }
}

.section-heading-container-between {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-bottom: 1rem;
  margin-top: 1rem;

  @include media-breakpoint-up(md) {
    margin-top: 2.5rem;
    align-items: center;
    flex-direction: row;
  }

  .section-heading-timer {
    font-size: 1.25rem;

    @include media-breakpoint-up(lg) {
      font-size: 1.375rem;
    }

    @include media-breakpoint-up(xl) {
      font-size: 1.5rem;
    }

    .timer-title {
      margin-right: 0.5rem;
    }

    .sd-timer {
      font-weight: 700;
      text-transform: uppercase;

      .colon {
        margin: 0 0.25rem;
      }
    }
  }
}

.copy-under-section-heading {
  margin-top: -0.5rem;
  color: $text-muted;
}

.view-all-btn {
  text-transform: uppercase;
  font-size: 0.875rem;
  margin-left: 1rem;
  line-height: 1.75;
  flex-shrink: 0;

  @include theme(light) {
    font-weight: 400;
  }
}

// -------- VIEW ALL CARD

.view-all-card-container {
  background-color: $card-background-dark;
  height: 100%;
  margin-top: 4px;
  border-radius: $border-radius;
  padding-bottom: calc(56.25% + 3rem);
  position: relative;

  @include theme(light) {
    background-color: map-get($light-theme, card-background);
    box-shadow: map-get($light-theme, card-box-shadow);
  }

  &.challenger {
    padding-bottom: calc(56.25% + 38px + 8px + 6px); // Height of cover + icon, price container + top padding + bottom padding
  }

  &:hover {
    background-color: $black;

    @include theme(light) {
      background-color: $grey-1;
    }

    .dot {
      background-color: $white;
    }
  }

  a {
    color: $text-white;
    font-weight: 400;
    text-align: center;

    @include theme(light) {
      color: map-get($light-theme, font-color);
    }
  }

  .view-all-content {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .dot-color {
    background-color: $grey-7;
  }

  .view-all-dots {
    width: 100%;
    margin-top: 0.5rem;
    display: flex;
    justify-content: center;

    .dot {
      color: transparent;
      border: 0;
      border-radius: 50%;
      background-color: $grey-7;
      height: 10px;
      width: 10px;
      margin: 0 1px;
      padding: 0;
      margin-right: 5px;
    }
  }
}

.prelive-warning-bar,
.admin-warning-bar {
  background-color: $error-core;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  justify-content: space-around;
  height: 24px;
  overflow: hidden;
  width: 100%;
  z-index: 1;
}

.admin-warning-bar {
  background-color: $warning-core;
  color: $black;
  height: 80px;
  padding-top: 18px;
  font-size: 24px;
  position: sticky;
  top: 0;
  z-index: 50000;
}

.recommendation-container {
  @include media-breakpoint-down(md) {
    .HitCardContainer:nth-child(4) {
      display: none;
    }
  }
  @include media-breakpoint-down(sm) {
    .HitCardContainer:nth-child(4) {
      display: block;
    }
  }
}

.recommendation-container-carousel-padding {
  padding-bottom: 3rem;
}

// -------- BORDERS

.border-primary {
  border: 3px solid $primary;
}

// -------- RIBBON

.ribbon {
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;

  span {
    font-size: 13px;
    color: $primary-button-text;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    line-height: 25px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 90px;
    display: block;
    background: $primary-core;
    position: absolute;
    top: 11px;
    right: -22px;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 100%;
      z-index: -1;
      border-left: 3px solid $orange-900;
      border-right: 3px solid transparent;
      border-bottom: 3px solid transparent;
      border-top: 3px solid $orange-900;
    }

    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 100%;
      z-index: -1;
      border-left: 3px solid transparent;
      border-right: 3px solid $orange-900;
      border-bottom: 3px solid transparent;
      border-top: 3px solid $orange-900;
    }
  }
}

// -------- RESPONSIVE VIDEO

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// -------- AGE RATINGS

.ageratings-container {
  height: 74px;

  .age-grid {
    display: flex;
    justify-content: center;
    align-items: center;

    .age-image-container {
      height: 50px;
      width: 50px;
      margin: 0 0.25rem;
      display: flex;
      justify-content: center;
      align-items: center;

      @include media-breakpoint-up(lg) {
        height: 40px;
        width: 40px;
      }

      @include media-breakpoint-up(xl) {
        height: 50px;
        width: 50px;
      }

      img {
        max-width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

// -------- SORTING / FILTERING

.dropdown-container {
  select {
    min-width: 150px;
    height: 40px;
    background-color: $white;
    color: $text-black;
    padding: 10px;
    border: none;
    font-size: 0.875rem;
    border-radius: $border-radius-sm;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image:
      linear-gradient(45deg, transparent 50%, $grey-10 50%),
      linear-gradient(135deg, $grey-10 50%, transparent 50%),
      linear-gradient(to right, $white, $white);
    background-position:
      calc(100% - 22px) calc(1em + 4px),
      calc(100% - 16px) calc(1em + 4px),
      calc(100% - 2.5em) 0.5em;
    background-size:
      6px 6px,
      6px 6px,
      1px 1.5rem;
    background-repeat: no-repeat;

    @include theme(light) {
      border: 1px solid map-get($light-theme, input-border-color);
    }

    &:hover {
      color: $text-black;
      cursor: pointer;
    }

    &:focus {
      color: $text-black;
      box-shadow: none;
      outline: none;
    }
  }
}

.checkbox-container {
  input[type="checkbox"] {
    display: inline-block;
    float: left;
    width: 18px;
    height: 18px;
    position: relative;
    -webkit-appearance: none;
    background-color: $white;
    text-align: center;
    margin: 0 0.2rem 0 0;
    line-height: 1.5rem;
    cursor: pointer;
    flex-shrink: 0;

    @include theme(light) {
      border: 1px solid map-get($light-theme, input-border-color);
    }

    &:focus {
      outline: 0;
    }

    &:checked {
      border: 2px solid $info-core;
      background-color: $info-core;
    }

    &:checked::before {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      background: url("data:image/svg+xml;utf8,<svg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 342.357 342.357' style='enable-background:new 0 0 342.357 342.357' xml:space='preserve'><polygon points='290.04,33.286 118.861,204.427 52.32,137.907 0,190.226 118.862,309.071 342.357,85.606 '/></svg>") no-repeat center center/100%;

      @include theme(light) {
        background: url("data:image/svg+xml;utf8,<svg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 342.357 342.357' style='enable-background:new 0 0 342.357 342.357' xml:space='preserve'><polygon style='fill:white;' points='290.04,33.286 118.861,204.427 52.32,137.907 0,190.226 118.862,309.071 342.357,85.606 '/></svg>") no-repeat center center/100%;
      }
    }
  }
}

.pill-filter-container {
  display: flex;
  background-color: $card-background-dark;
  padding: 0.5rem 1rem;
  margin-right: 1rem;
  border-radius: 40px;
  white-space: nowrap;
  font-weight: 400;
  color: $text-white;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border: none;

  &:hover {
    background-color: $grey-4;
    color: $text-black;
  }

  @include theme(light) {
    background-color: $white;
    color: map-get($light-theme, font-color);
    box-shadow: map-get($light-theme, card-box-shadow);

    &:hover {
      background-color: $grey-1;
    }
  }

  &.selected {
    color: $text-black;
    background-color: $white;

    @include theme(light) {
      background-color: $grey-3;
      box-shadow: map-get($light-theme, card-box-shadow);
    }
  }
}

// --- Back to product banner

.back-to-product-banner {
  background-color: map-get($dark-theme, secondary-background-color);
  color: $text-white;
  padding: 0.5rem 0;

  @include theme(light) {
    background-color: map-get($light-theme, card-background);
    color: map-get($light-theme, font-color);
    box-shadow: map-get($light-theme, card-box-shadow);
  }

  a {
    color: $text-white;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 12px;

    @include theme(light) {
      color: map-get($light-theme, font-color);
    }

    &:hover {
      color: $text-white;
      text-decoration: underline;

      @include theme(light) {
        color: map-get($light-theme, font-color);
      }
    }
  }

  svg {
    margin-right: 1rem;
  }
}

// --- Breadcrumbs

.site-breadcrumbs {
  color: $text-white;
  padding-bottom: 2rem;
  position: relative;
  z-index: 1;

  @include theme(light) {
    color: map-get($light-theme, font-color);
  }

  @include media-breakpoint-up(md) {
    margin-top: -0.5rem;
    padding-bottom: 2.5rem;
  }

  .breadcrumb {
    background-color: transparent;
    align-items: center;

    svg {
      font-size: 12px;
      margin: 0 0.5rem;
      color: $grey-5;

      @include theme(light) {
        color: $grey-4;
      }

      @include media-breakpoint-down(xs) {
        display: none;

        &:last-of-type {
          display: block;
        }
      }

      @include media-breakpoint-up(md) {
        margin: 0 1rem;
      }
    }

    .breadcrumb-item {
      font-weight: 400;
      font-size: 13px;

      @include media-breakpoint-down(xs) {
        display: none;

        &:last-of-type,
        &:nth-last-of-type(2) {
          display: block;
        }
      }

      &.active {
        color: $grey-5;
        // font-weight: 700;
      }

      a {
        color: $grey-5;

        &:hover {
          @include brand-property(color, brand-core);

          text-decoration: underline;
        }
      }

      &::before {
        display: none;
      }
    }
  }
}

li[class*="col-"] {
  list-style: none;
}

// Helper class to make links in an element blend in with the rest of the text
.disguise-links {
  a {
    color: inherit;

    &.visible-link {
      text-decoration: underline;
    }
  }
}

// Make a ul > li structure have no bullets and no breaking
.inline-list {
  display: inline;
  margin: 0 0 0 8px;
  padding: 0;

  li {
    list-style: none;
    display: inline;
    margin-right: 8px;
  }
}

a {
  &:hover {
    text-decoration: none;
  }
}

// Hide moengage by default, let split test make it appear later
#moe-push-div {
  display: none;
}

.small-print {
  font-size: 0.875rem; /* 14/16 */
}

.facet-header {
  background-color: $black;

  .facet-header-img-container {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
      height: 160px;
    }

    @include media-breakpoint-up(lg) {
      height: 210px;
    }

    @include media-breakpoint-up(xl) {
      height: 250px;
    }

    @include media-breakpoint-up(xxl) {
      height: 300px;
    }

    img {
      height: 100%;
      width: auto;
      object-fit: cover;
    }
  }
}

.metacritic-list-title {
  display: flex;
  align-items: center;
  min-height: 27px;

  @include media-breakpoint-up(sm) {
    min-height: 28px;
  }

  @include media-breakpoint-up(lg) {
    min-height: 33px;
  }

  @include media-breakpoint-up(xxl) {
    min-height: 35px;
  }

  .svg-container {
    margin-left: 0.5rem;
    width: 110px;
    height: 27px;

    @include media-breakpoint-up(sm) {
      width: 120px;
      height: 28px;
    }

    @include media-breakpoint-up(xl) {
      width: 130px;
      height: 33px;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
}

.bounce {
  animation-name: bounce;
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% { transform: translateY(0); }
  40% { transform: translateY(-4px); }
  60% { transform: translateY(-2px); }
}

.grecaptcha-badge {
  display: none !important;
}
