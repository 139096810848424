.simple-modal {
  max-width: 300px;
  display: flex;
  height: 100%;
  align-items: center;
  margin: 0 auto;
  font-weight: 400;

  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem 1rem 1rem;

    @include media-breakpoint-up(md) {
      padding: 1.75rem 1.5rem;
    }

    @include media-breakpoint-up(lg) {
      padding: 2rem;
    }
  }

  @include media-breakpoint-up(md) {
    max-width: 400px;
  }

  h1 {
    text-align: center;
    font-weight: 700;
    font-size: 1.2rem;
  }

  .message-container {
    text-align: center;
  }

  .buttons-container {
    display: flex;
    justify-content: space-around;

    .cancel-button-container {
      width: 45%;

      .cancel-button {
        background-color: $grey-4;
        border-color: $grey-4;
        color: $black;

        &:hover {
          background-color: $grey-5;
        }
      }
    }

    .action-button-container {
      width: 45%;
    }

    button {
      text-transform: uppercase;
      font-weight: 700;
      width: 100%;
    }
  }
}

.simple-modal,
.modal {
  .close-button {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 6px;
    z-index: 1060;
  }
}

.modal-footer-close {
  justify-content: center;
  padding: 0.75rem;
  border: none;

  .btn {
    text-transform: uppercase;
  }
}

// BOOTSTRAP MODAL

.modal-dialog {
  @include media-breakpoint-up(lg) {
    max-width: 620px;
  }

  .modal-content {
    color: $text-black;
    border-radius: 0;
    border: 0;

    .modal-header {
      border: 0;
      margin-bottom: 0.75rem;
      text-align: center;
      font-weight: 400;
      font-size: 1.5rem;
      padding: 24px 24px 0 24px;

      @include media-breakpoint-up(sm) {
        padding: 26px 30px 0 30px;
      }

      @include media-breakpoint-up(xl) {
        padding: 32px 40px 0 40px;
      }

      .close {
        border: 0;
        padding: 0;
        color: $grey-4;
      }

      .modal-title {
        margin-bottom: 0;
        text-align: center;
        font-weight: 400;
        font-size: 1.5rem;
        width: 100%;
      }
    }

    .modal-body {
      padding: 0 24px 24px 24px;

      @include media-breakpoint-up(sm) {
        padding: 0 30px 30px 30px;
      }

      @include media-breakpoint-up(xl) {
        padding: 0 40px 40px 40px;
      }

      .button-container {
        display: flex;
        align-items: center;
        justify-content: center;

        button,
        .btn {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          text-transform: uppercase;
          font-size: 0.875rem;
          min-height: 40px;
          min-width: 240px;
        }
      }
    }

    .close-button-container {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 24px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 3;
      margin: 5px;

      button {
        width: 100%;
        background: transparent;
        border: 0;
        padding: 0;
      }

      svg {
        color: $grey-4;
      }
    }
  }
}
